import React, {useEffect, useState} from 'react';
import {COOKIES_TYPES, CookiesService, loggedUser} from '@bri/shared-components';
import {useRecoilState} from 'recoil';
import {Link} from 'react-router-dom';
import {AntDesign, FontAwesome, Ionicons, MaterialIcons} from '@native-base/icons';
import {Box, Flex, Hidden, HStack, Icon, Image, Link as NBLink, Pressable, ScrollView, Text, useColorModeValue, View, VStack} from 'native-base';
import {useTranslation} from 'react-i18next';
import './Navbar.css';
import jwt from 'jwt-decode'; // import dependency
import {USER_ACTIONS, USER_ROLE} from '@bri/rotimpres-core';
import logo from '../../images/logo.png';
import logoMenu from '../../images/menu_logo.png';
import NavbarItem from './NavbarItem';

const MENU_WIDTH = 250;
export const MENU_ITEM_WIDTH = 160;

function Navbar() {
  const {t} = useTranslation();
  const [sidebar, setSidebar] = useState(false);
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const showSidebar = () => setSidebar(!sidebar);
  const [user, setUser] = useRecoilState(loggedUser);
  const [menu, setMenu] = useState<any[]>([]);
  const navBarBackgroundColor = useColorModeValue('#fff', 'rgb(17, 24, 39)');

  const NavMenuData = [
    // {
    //   title: t('Settings'),
    //   path: '/cookies-config',
    //   cName: 'nav-text',
    //   icon: 'setting',
    //   iconFamily: AntDesign,
    // },
    {
      title: t('Log out'),
      path: '/',
      cName: 'nav-text',
      icon: 'logout',
      iconFamily: MaterialIcons,
      onClick: async () => {
        await CookiesService.removeKey(COOKIES_TYPES.TECHNICAL, 'access_token');
        await CookiesService.removeKey(COOKIES_TYPES.TECHNICAL, 'refresh_token');
        setUser(null);
      },
    },
  ];

  const GenerateSidebarData = () => [
    {
      title: t('Home'),
      path: '/',
      cName: 'nav-text',
      icon: 'home',
      iconFamily: AntDesign,
    },
    {
      title: t('Clients'),
      path: '/listCustomers',
      cName: 'nav-text',
      icon: 'user',
      iconFamily: AntDesign,
      action: USER_ACTIONS.CUSTOMER_CRUDL,
    },
    {
      title: t('Users'),
      path: user?.role === USER_ROLE.ADMIN ? '/listUsers' : '/listCustomerUsers',
      cName: 'nav-text',
      icon: 'user',
      iconFamily: AntDesign,
      action: USER_ACTIONS.USER_CRUDL,
    },
    {
      title: t('Products'),
      path: '/listProducts',
      cName: 'nav-text',
      icon: 'folderopen',
      iconFamily: AntDesign,
      action: USER_ACTIONS.PRODUCT_CRUDL,
    },
    {
      title: t('Settings'),
      path: '/cookiesConsent',
      cName: 'nav-text',
      icon: 'setting',
      iconFamily: AntDesign,
    },
  ];
  let SidebarData = GenerateSidebarData();

  useEffect(() => {
    const handleResize = () => {
      // do magic for resize
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const token = await CookiesService.getType(COOKIES_TYPES.TECHNICAL, 'access_token');
      const token_decoded = jwt(token); // decode your token here

      const actions: string[] = (token_decoded as any).aud.split(' ');

      SidebarData = GenerateSidebarData();

      // Pilla les actions del token
      setMenu(SidebarData.filter(item => item.action === undefined || actions.includes(`ACTION:${item.action}`)));
    };
    fetchData().catch(error => {
      console.error(error);
    });
  }, [t]);

  if (menu.length === 0) {
    return null;
  }

  const roleToName = () => {
    switch (user?.role) {
      case USER_ROLE.ADMIN:
        return t('Admin');
      case USER_ROLE.USER:
        return t('User');
      case USER_ROLE.CLIENT:
        return t('Customer');
      default:
        return '';
    }
  };

  return (
    <>
      <div className="navbar" style={{backgroundColor: navBarBackgroundColor}}>
        <Hidden from="lg">
          <Link to="#" className="navbar-link">
            <Icon
              as={Ionicons}
              name="menu"
              size={6}
              color="primary.500"
              onPress={event => {
                event.preventDefault();
                showSidebar();
              }}
            />
            {/* <Ionicons name="menu" size={24} color="black" onClick={showSidebar} /> */}
          </Link>
        </Hidden>

        <Flex style={{flex: 1, justifyContent: 'center', alignItems: 'flex-end'}}>
          <Pressable onPress={() => setIsNavExpanded(!isNavExpanded)}>
            <HStack mr={25} space={3} alignItems="center">
              <Image source={{uri: logo}} resizeMode="cover" size={50} borderRadius={25} alt="NFCTrack logo" />
              <VStack>
                <Text fontSize="md">{user?.name}</Text>
                <Text fontSize="xs">{roleToName()}</Text>
              </VStack>
            </HStack>
          </Pressable>
        </Flex>

        <div className={isNavExpanded ? 'user-menu expanded' : 'user-menu'}>
          <VStack
            style={{
              position: 'absolute',
              flexDirection: 'column',
              height: 'auto',
              top: 80,
              right: 20,
              minWidth: 200,
              borderTopWidth: 1,
              backgroundColor: '#ccc',
              borderTopColor: 'black',
              display: isNavExpanded ? 'flex' : 'none',
            }}>
            {NavMenuData.map((item, index) => (
              <Box key={index} style={{height: 60, justifyContent: 'flex-start'}}>
                <Pressable _hover={{backgroundColor: 'gray.400'}} style={{height: '100%'}}>
                  <Link
                    to={item.path}
                    style={{
                      textDecoration: 'none',
                      height: '100%',

                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      display: 'flex',
                      paddingRight: 16,
                      paddingLeft: 16,
                    }}
                    onClick={async () => {
                      setIsNavExpanded(false);
                      item.onClick && item.onClick();
                    }}>
                    <Icon as={item.iconFamily} name={item.icon} size={5} color="primary.500" mr={2.5} />
                    <Text>{item.title}</Text>
                  </Link>
                </Pressable>
              </Box>
            ))}
          </VStack>
        </div>
      </div>
      <View
        // className={sidebar || windowWidth >= 1009 ? 'nav-menu active' : 'nav-menu'}
        style={[
          {
            width: MENU_WIDTH,
            height: '100vh',
            display: 'flex',
            justifyContent: 'space-between',
            position: 'fixed' as any,
            top: 0,
            left: '-100%',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            transition: '850ms',
            borderBottomRightRadius: 20,
            borderTopRightRadius: 20,
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          (sidebar || windowWidth >= 1009) && {left: 0, transition: '450ms'},
        ]}
        backgroundColor="primary.500">
        <VStack style={{width: '100%'}} flex={1}>
          <Hidden from="lg">
            <Pressable style={{height: 80, justifyContent: 'center', alignItems: 'flex-start'}} onPress={showSidebar}>
              <Link to="#" className="navbar-link">
                <Icon as={FontAwesome} name="close" size={6} color="secondary.500" />
              </Link>
            </Pressable>
          </Hidden>
          <Hidden till="lg">
            <Pressable style={{height: 125, justifyContent: 'center', alignItems: 'center'}}>
              <Link to="#">
                <Image source={{uri: logoMenu}} resizeMode="contain" size="lg" alt="NFCTrack logo" />
              </Link>
            </Pressable>
          </Hidden>
          <ScrollView flex={1} contentContainerStyle={{flexGrow: 1, justifyContent: 'space-between'}}>
            <View>
              {menu.map((item, index) => (
                <NavbarItem key={index} item={item} onPress={showSidebar} />
              ))}
            </View>
            <VStack mb={4} mx={4} space={2}>
              <Link to="cookies" style={{textDecoration: 'none'}} onClick={showSidebar}>
                <NBLink
                  isUnderlined={false}
                  _text={{
                    color: 'secondary.500',
                  }}>
                  {t('Cookies')}
                </NBLink>
              </Link>
              <Link to="terminos-y-condiciones" style={{textDecoration: 'none'}} onClick={showSidebar}>
                <NBLink
                  isUnderlined={false}
                  _text={{
                    color: 'secondary.500',
                  }}>
                  {t('Terms and Conditions')}
                </NBLink>
              </Link>
              <Link to="privacyPolicy" style={{textDecoration: 'none'}} onClick={showSidebar}>
                <NBLink
                  isUnderlined={false}
                  _text={{
                    color: 'secondary.500',
                  }}>
                  {t('Privacy Policy')}
                </NBLink>
              </Link>
            </VStack>
          </ScrollView>
        </VStack>
      </View>
    </>
  );
}

export default Navbar;
