import {FC, useEffect, useReducer, useState} from 'react';
import {Icon, VStack} from 'native-base';
import {Filters, SortCriteria, SortedFilteredPaginatedRequest} from '@bri/shared-core';
import {TableColumn, TableStyles} from 'react-data-table-component';
import {COOKIES_TYPES, CookiesService, CustomDataTable, DataRowRequiredData, FilterOptions, loggedUser} from '@bri/shared-components';
import {IUser, USER_ROLE} from '@bri/rotimpres-core';
import {Pressable} from 'react-native';
import {useTranslation} from 'react-i18next';
import {FontAwesome} from '@native-base/icons';
import {useNavigate} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {useUserService} from '../services/UsersService';

type Props = {
  editHandler?: (id: string) => void;
  columns?: {label: string; field: string; format?: (row: DataRow) => string; sortField?: string; filterable?: boolean; filterOptions?: FilterOptions}[];
  clientId?: string;
};

export type DataRow = DataRowRequiredData & (IUser & {nTags: number});

export const ListUsers: FC<Props> = props => {
  const [user] = useRecoilState(loggedUser);
  const userService = useUserService();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [sort, setSort] = useState<SortCriteria>({});
  const [filters, setFilters] = useState<Filters>([]);

  const [data, setData] = useState<DataRow[]>([]);

  const [dataForce, forceUpdate] = useReducer(x => x + 1, 0);

  async function fetchData() {
    if (!(await CookiesService.hasKey(COOKIES_TYPES.TECHNICAL, 'access_token'))) {
      return;
    }

    const params: SortedFilteredPaginatedRequest = {page, pageSize, sort, filters};

    if (props.clientId) {
      userService
        .listClientUsersById({clientId: props.clientId, ...params})
        .response(resp => {
          setData(resp.results as any);
          setTotalResults(resp.total);
        })
        .error(err => console.log(err));
    } else if (user?.role === USER_ROLE.CLIENT) {
      userService
        .listClientUsers(params)
        .response(resp => {
          setData(resp.results as any);
          setTotalResults(resp.total);
        })
        .error(err => console.log(err));
    } else {
      userService
        .list({...params, role: USER_ROLE.USER})
        .response(resp => {
          setData(resp.results as any);
          setTotalResults(resp.total);
        })
        .error(err => console.log(err));
    }
  }

  useEffect(() => {
    fetchData().catch(console.error);
  }, [page, pageSize, sort, dataForce]);

  const customStyles: TableStyles = {
    tableWrapper: {
      style: {
        padding: 4,
      },
    },
    table: {
      style: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
      },
    },
    header: {
      style: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
      },
    },
    pagination: {
      style: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
      },
    },
  };

  let columns: TableColumn<DataRow>[] = [
    {
      name: '_id',
      selector: (row: DataRow) => row._id,
    },
    {
      name: 'Username',
      selector: (row: DataRow) => row.local.username,
    },
    {
      name: 'Role',
      selector: (row: DataRow) => row.role,
    },
  ];
  if (props.columns) {
    columns = props.columns.map(function(column) {
      let filterOptions: FilterOptions | undefined;

      if (column.filterable) {
        filterOptions = column.filterOptions || {fields: column.field};
      }

      return {
        name: column.label,
        selector: (row: DataRow) => {
          if (column.field.indexOf('.') > -1) {
            return column.field.split('.').reduce((obj: any, key) => obj[key], row);
          }

          return row[column.field];
        },
        format: column.format,
        sortable: (column.sortField as unknown) as boolean,
        sortField: column.sortField || column.field,
        filterable: column.filterable,
        filterOptions,
      } as TableColumn<DataRow>;
    });
  }

  if (user?.role === USER_ROLE.CLIENT) {
    columns.push({
      name: t('Actions'),
      center: true,
      style: customStyles,
      cell: item => (
        <Pressable onPress={() => navigate(`/listUserTags/${item._id}`)}>
          <Icon as={FontAwesome} name="list-ul" size={4} color="primary.400" />
        </Pressable>
      ),
    });
  }

  return (
    <VStack space={4} alignItems="center">
      <CustomDataTable
        title=""
        data={data}
        columns={columns}
        export
        pagination
        style={{marginTop: 100}}
        defaultActions={false}
        showExtensions={false}
        customStyles={customStyles}
        editHandler={props.editHandler}
        deleteHandler={forceUpdate}
        paginationServer
        sortServer
        totalRows={totalResults}
        onChangePage={newPage => setPage(newPage)}
        onChangeRowsPerPage={newPageSize => setPageSize(newPageSize)}
        onSort={(newSort: SortCriteria) => setSort(newSort)}
        onFilter={(newFilters: Filters) => {
          setFilters(newFilters);
          forceUpdate();
        }}
      />
    </VStack>
  );
};
