import {Cookies} from '@bri/shared-components';
import {PageLayout} from '../components/PageLayout';

const CookiesPage = () => (
  <PageLayout>
    <Cookies
      company={{
        name: 'Impressions Rotatives Offset SA',
        id: 'A17166794',
        address: "Calle pla de L'estany (pol. ind. casa Nova), S/N, Aiguaviva, 17181, Girona",
        contactEmail: 'support@nfctrack.com',
      }}
      projectName="NFC track"
      appOrUrl="NFTTrack"
    />
  </PageLayout>
);

export default CookiesPage;
