import {Login} from '@bri/shared-components';
import {Flex} from 'native-base';
import logo from '../images/logo.png';

const LoginPage = () => (
  <div
    style={{
      width: '100%',
      backgroundImage: 'url(/login_background.jpg)',
      backgroundSize: 'cover',
    }}>
    <div
      style={{
        width: '100%',
        background: 'rgba(0, 89, 167, 0.8)',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Flex h={window.innerHeight} style={{justifyContent: 'center', alignItems: 'center'}}>
        <Login logo={{file: logo, width: 200, height: 180}} scope="ROLE:10 ROLE:3" />
      </Flex>
    </div>
  </div>
);

export default LoginPage;
