import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilState} from 'recoil';
import {loggedUser} from '@bri/shared-components';
import {IUser, USER_ROLE} from '@bri/rotimpres-core';
import moment from 'moment';
import {DataRow, ListUsers} from './ListUsers';

export const ListAllUsers: FC = () => {
  const [user] = useRecoilState(loggedUser);
  const {t} = useTranslation();

  const columns = [
    {label: t('First name'), field: 'name', sortField: 'name', filterable: true, filterOptions: {fields: ['name', 'familyName'], label: t('Name')!}},
    {label: t('Last name'), field: 'familyName', sortField: 'familyName'},
    {label: t('Email'), field: 'email', sortField: 'email'},
    {label: t('Phone number'), field: 'telephone', sortField: 'telephone'},
    {label: t('Country'), field: 'address.addressCountry', sortField: 'address.addressCountry'},
    {
      label: t('Birth date'),
      sortField: 'birthDate',
      field: 'birthDate',
      format: (row: DataRow) =>
        moment(row.birthDate) /* .locale('es-es') */
          .format('LL'),
    },
    {label: t('Prefered language'), field: 'language', sortField: 'language'},
    {
      label: t('Gender'),
      field: 'gender',
      sortField: 'gender',
      format: (row: DataRow) => {
        const genders: Record<string, string> = {
          m: t('Male'),
          f: t('Female'),
          nb: t('Non-binary'),
          other: t('Other'),
          nc: t('Unknown'),
        };

        if (!row.gender || !(row.gender in genders)) {
          return '-';
        }

        return genders[row.gender];
      },
    },
    {label: t('Tags'), field: 'nTags', sortField: 'nTags'},
  ];

  if (user?.role === USER_ROLE.ADMIN) {
    columns.push({label: t('Brands'), field: 'brandsCount', sortField: 'brandsCount'});
    columns.unshift({
      label: t('Client'),
      field: 'firstClient.name',
      sortField: 'firstClient.name',
      format: (row: DataRow) => ((row.firstClient as unknown) as IUser)?.name || '-',
    });
  }

  return <ListUsers columns={columns} />;
};
