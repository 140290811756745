import {ChangeEvent, ComponentProps, useContext, useEffect, useRef, useState} from 'react';
import {
  ConfirmationDialogContext,
  DatePicker,
  loggedUser,
  Map,
  TOAST_STATUS,
  TOAST_VARIANT,
  useSharedToast,
  MultilanguageField,
  MultilanguageTab,
} from '@bri/shared-components';
import {useNavigate, useParams, useRouteError} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {Message} from 'yup';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {Box, Button, Center, Checkbox, FormControl, Heading, HStack, Icon, Input, Menu, Stack, Text, View, VStack} from 'native-base';
import {AntDesign, Ionicons, MaterialCommunityIcons} from '@native-base/icons';
import ImageUploading, {ImageListType} from 'react-images-uploading';
import {Pressable} from 'react-native';
import {AxiosError} from 'axios';
import {AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE, ITag, ProductDTO} from '@bri/rotimpres-core';
import {useRecoilState} from 'recoil';
import {Markers} from '../utils/imagesUtils';
import Config from '../config/config';
import Certificates from '../components/Certificates';
import {useProductsService} from '../services/ProductsService';
import {PageLayout} from '../components/PageLayout';
import {useTagsService} from '../services/TagsService';

import {getCurrentLanguage} from '../utils/languageUtils';

type Props = {
  // TODO
  uploadedDevMap?: {name: string; file?: any};
};

const ModifyProductPage = (props: Props) => {
  const {t} = useTranslation();
  const {productId} = useParams();
  const productService = useProductsService();
  const tagService = useTagsService();
  const [product, setProduct] = useState<ProductDTO | null>(null);
  const sharedToast = useSharedToast();
  const [images, setImages] = useState<ImageListType>([]);
  const [promoImages, setPromoImages] = useState<ImageListType[]>([]);
  const [mapZoom, setMapZoom] = useState<number | undefined>(4);
  const [startCords, setStartCords] = useState<{coords: {lat: number; lng: number}}>({coords: {lat: 41.8, lng: 1}});
  const maxNumber = 1;
  const navigate = useNavigate();
  const [devolutionMap, setDevolutionMap] = useState<{name: string; file?: any}>(props.uploadedDevMap ?? {name: 'devolutionMap'});
  const [returnPoints, setreturnPoints] = useState<{latitude: number; longitude: number; address: string; name: string}[]>([]);
  const actualDate = new Date();
  const twoMonthFromNow = new Date(actualDate.getFullYear(), actualDate.getMonth() + 2, actualDate.getDate());
  const [nameTagList, setNameTagList] = useState<{tags: string}[]>([]);
  const inputFileRefDevolution = useRef(null);
  const inputFileRefTags = useRef(null);
  const [tagListdata, setTagListData] = useState<ITag[]>([]);
  const [selectedTag, setSelectedTag] = useState<ITag | null>(null);
  const [checkedState, setCheckedState] = useState<boolean[]>([]);
  const [infoSelected, setInfoSelected] = useState<boolean>(true);
  const [submittingCreate, setSubmittingCreate] = useState(false);
  const [submittingPreview, setSubmittingPreview] = useState(false);
  const [canEditTag, setcanEditTag] = useState<boolean>(false);
  const [allTagsCheckbox, setAllTagsCheckbox] = useState(false);
  const [user] = useRecoilState(loggedUser);
  const removeTagDialog = useContext(ConfirmationDialogContext);
  const [previewLanguage, setPreviewLanguage] = useState(getCurrentLanguage());

  const checkIfProductTagsAlreadyExist = function(tags: string[]): Promise<Record<string, boolean>> {
    return new Promise((resolve, reject) => {
      productService
        .existingTags({tags})
        .response(data => resolve(data.exists))
        .error(error => reject(error));
    });
  };

  const statusColor = (tag: ITag) => {
    const today = new Date();
    if (Date.parse(tag.expirationDate.toString()) < today.getTime()) {
      return '#F56060';
    }
    if (tag.owner === user?._id || tag.owner == '') {
      return '#D2D2D2';
    }
    return '#189624';
  };

  useEffect(() => {
    if (checkedState.filter(cs => cs).length > 1 && !infoSelected) {
      sharedToast({
        title: t('Selected Tags'),
        description: t('Multiple tags are selected, saving will modify the tag data for all the selected tags'),
        isClosable: true,
        status: TOAST_STATUS.INFO,
      });
      setInfoSelected(!infoSelected);
    } else if (checkedState.filter(cs => cs).length == 0) {
      setInfoSelected(!infoSelected);
    }
  }, [checkedState]);

  useEffect(() => {
    // Use Effect de tipus tagListData per controlar canvis
    const checkstatetemp: boolean[] = [];
    tagListdata.forEach(() => {
      checkstatetemp.push(false);
    });
    setCheckedState(checkstatetemp);
  }, [tagListdata]);

  const convertImageUrlToImageType = (url: string[]): ImageListType[] => {
    let imgList: ImageListType[] = [];
    imgList = url
      .filter(undefindeMap => undefindeMap != undefined)
      .map(mapUrl => {
        const dataURL = mapUrl;
        const file: File = new File([dataURL], 'image.jpg', {type: 'image/jpeg'});
        return [
          {
            data_url: dataURL,
            file,
            alreadyExists: true,
          },
        ];
      });
    return imgList;
  };

  const MultilanguageSchema = (params: {required: boolean}) =>
    Yup.object().test('multilanguageField', t('Required') as Message, (value: {[key: string]: string}) => {
      const text = value && Object.hasOwn(value, DEFAULT_LANGUAGE) ? value[DEFAULT_LANGUAGE] : '';
      return params.required
        ? Yup.string()
            .required()
            .isValidSync(text)
        : Yup.string()
            .notRequired()
            .isValidSync(text);
    });

  const ProductTagSchema = Yup.string()
    .required(t('Required') as Message)
    .min(8, t('Must be more than 8 digits') as Message)
    .max(50, t('Must be less than 50 digits') as Message)
    .matches(/^[a-zA-Z0-9]+$/, {message: 'Invalid Format'});

  const alphanumericRegex = /^[a-zA-Z0-9]+$/;
  const PromotionCodeSchema = () =>
    Yup.string().test('is-alphanumeric', t('Promotion code must be alphanumeric') as Message, value => !value || !!value.match(alphanumericRegex));

  const ProductSchema = Yup.object().shape({
    name: MultilanguageSchema({required: true}),
    eligibleQuantity: Yup.number()
      .typeError(t('Must be a number') as Message)
      .required(t('Required') as Message),
    batch: Yup.string().required(t('Required') as Message),
    promotionCode: PromotionCodeSchema(),
    description: MultilanguageSchema({required: true}),
    nft_expiration: Yup.string().required(t('Required') as Message),
    devolution_map: Yup.string().notRequired(),
    promotions: Yup.array().of(
      Yup.object().shape({
        title: MultilanguageSchema({required: true}),
        expiration_date: Yup.string().required(t('Required') as Message),
        description: MultilanguageSchema({required: false}),
      })
    ),
  });

  const onImageChange = (imageList: ImageListType, addUpdateIndex: any) => {
    // data for submit
    setImages(imageList);
  };

  const onPromoImageChange = (imageList: ImageListType, addUpdateIndex: any) => {
    setPromoImages(prevImages => {
      const newImages = [...prevImages];
      newImages[addUpdateIndex] =
        imageList.length != 0
          ? [
              {
                data_url: imageList[0].data_url,
                file: imageList[0].file,
                alreadyExists: false,
              },
            ]
          : imageList;
      console.log(newImages[addUpdateIndex]);
      return newImages;
    });
  };

  const handleRemoveImage = (indexToRemove: number) => {
    setPromoImages(prevImages => prevImages.filter((_, index) => index !== indexToRemove));
  };

  useEffect(() => {
    if (productId) {
      productService
        .getDTOEdit({id: productId!})
        .response(response => {
          reset(getInitialValues(response.product));
          setProduct(response.product);
        })
        .error(error => {
          console.error(error);
          sharedToast({
            title: t('Error retrieving product'),
            description: error.data,
            status: TOAST_STATUS.ERROR,
            variant: TOAST_VARIANT.SUBTLE,
          });
        });
    }
  }, []);

  const setTagDataRows = async (tagsListDataRows: {tags: string}[]) => {
    const tagsFilteredDataRows: ITag[] = [];
    const tagsFilteredListDataRows: {tags: string}[] = [];
    tagsListDataRows.map((x, index) => {
      if (!tagsFilteredDataRows.find(y => y.code == x.tags)) {
        tagsFilteredListDataRows.push({tags: x.tags});
        tagsFilteredDataRows.push(JSON.parse(x.tags));
      }
    });
    setNameTagList(tagsFilteredListDataRows);
    setTagListData(tagsFilteredDataRows);
  };

  const generateObjProduct = (values: any) => {
    const promotionCode = ((values.promotionCode as string) || '').toUpperCase();

    const objProduct = {
      _id: productId,
      name: JSON.stringify(values.name),
      description: JSON.stringify(values.description),
      tags: nameTagList instanceof Array ? nameTagList.map((item: any) => item.tags) : [],
      defaultCertificates: values.certificates ? values.certificates.split(',') : [],
      uploadedCertificates: values.uploadedCertificates ?? [],
      uploadedCertificateNames: values.uploadedCertificateNames ?? [],
      expirationDate: values.nft_expiration,
      units: nameTagList.length,
      batch: values.batch,
      promotionCode,
      promotions:
        values.promotions instanceof Array && values.promotions.length > 0
          ? JSON.stringify(
              values.promotions.map((item: any) => ({
                name: item.title,
                expiration_date: item.expiration_date as Date,
                description: item.description,
                promotionCode,
              }))
            )
          : '',
      uploadedPromoImages: promoImages.length > 0 ? promoImages.map((item: any) => item[0].file) : [],
      returnPoints: JSON.stringify(returnPoints) ?? [],
      image: values.image,
    } as ProductDTO;

    return objProduct;
  };

  const generateTag = (values: any) =>
    ({
      promotions:
        values.promotions instanceof Array && values.promotions.length > 0
          ? values.promotions.map((item: any) => ({
              name: item.title as string,
              expiration_date: item.expiration_date as Date,
              description: item.description as string,
              promotionCode: ((values.promotionCode as string) || '').toUpperCase(),
            }))
          : [],
      returnPoints:
        returnPoints.map((rp: any) => ({
          address: rp.address,
          name: rp.name,
          location: {type: 'Point', coordinates: [rp.latitude, rp.longitude]},
        })) ?? [],
      expirationDate: values.nft_expiration,
    } as any);

  const submitFormCreate = (values: any) => {
    const tagBase: any = generateTag(values);
    const imgPromoMeta: {alreadyExists: boolean; dataUrl: string}[] = [];
    const promoImagesSend =
      promoImages.length > 0
        ? promoImages
            .map((item: any) => {
              if (item[0] != undefined) {
                imgPromoMeta.push({alreadyExists: item[0].alreadyExists, dataUrl: item[0].data_url});
                return item[0].file;
              }
              return undefined;
            })
            .filter(i => i != undefined)
        : [];
    const sendTags = {
      ITagBase: tagBase,
      listTags: checkedState
        .map((element, index) => {
          if (element === true) {
            return {id: tagListdata[index]._id, code: tagListdata[index].code};
          }
        })
        .filter(a => {
          if (a != undefined) return true;
          return false;
        }),
      productId: product?._id,
      imgPromoMeta,
    };
    setSubmittingCreate(true);
    productService
      .updateTags({tags: JSON.stringify(sendTags), promoImages: promoImagesSend})
      .response(response => {
        sharedToast({
          title: t('Product update'),
          description: '',
        });
        navigate(-1);
      })
      .error(error => {
        console.error(error);
        sharedToast({
          title: t('Validation error'),
          description: t('Something went wrong trying to update the product'),
        });
      })
      .finally(() => setSubmittingCreate(false));
    setSubmittingCreate(false);
  };

  const submitFormPreview = (values: any) => {
    const objProduct: any = generateObjProduct(values);
    const imgPromoMeta: {alreadyExists: boolean; dataUrl: string}[] = [];
    const promoImagesSend =
      promoImages.length > 0
        ? promoImages
            .map((item: any) => {
              if (item[0] != undefined) {
                imgPromoMeta.push({alreadyExists: item[0].alreadyExists, dataUrl: item[0].data_url});
                return item[0].file;
              }
              return undefined;
            })
            .filter(i => i != undefined)
        : [];
    setSubmittingPreview(true);
    productService
      .savePreviewEdit({product: JSON.stringify(objProduct), promoImages: promoImagesSend, imgPromoMeta: JSON.stringify(imgPromoMeta), language: previewLanguage})
      .response(response => {
        window.open(`${Config.previewProduct.url}/PreviewProduct/${response.product._id}`, '_blank', 'noreferrer');
      })
      .error(error => {
        console.error(error);
        sharedToast({
          title: t('Validation error'),
          description: t('Something went wrong trying to preview a the product'),
        });
      })
      .finally(() => setSubmittingPreview(false));
  };

  const getInitialValues = (productBD: ProductDTO | null): {[key: string]: any} => {
    if (productBD) {
      // RETURN POINTS
      // Valors inicials, obtenir objecte return points a partir d'array d'strings
      let rppPost: {latitude: number; longitude: number; address: string; name: string}[] = []; // -> Format backoffice return points
      if (productBD.returnPoints != '' && productBD.returnPoints != undefined) {
        try {
          let returnPointsParsed: {address: string; name: string; location: {type: string; coordinates: number[]}[]}[] = []; // -> Format server return points
          returnPointsParsed = JSON.parse(productBD.returnPoints);
          returnPointsParsed.forEach(rpp => {
            rppPost.push({
              address: rpp.address,
              name: rpp.name,
              latitude: rpp.location[0].coordinates[0],
              longitude: rpp.location[0].coordinates[1],
            });
          });
        } catch {
          rppPost = [];
        }
      }
      setreturnPoints(rppPost);
      setNameTagList(productBD.tags.map(item => ({tags: item})));
      setTagDataRows(productBD.tags.map(item => ({tags: item})));
      if (rppPost.length > 0) {
        if (rppPost.length == 1) {
          setStartCords({coords: {lat: rppPost[0].latitude, lng: rppPost[0].longitude}});
          setMapZoom(undefined);
          setMapZoom(15);
        } else {
          setMapZoom(undefined);
        }
      } else {
        setMapZoom(4);
      }

      return {
        name: JSON.parse(productBD.name as string), // it's a json
        eligibleQuantity: productBD.units,
        batch: productBD.batch,
        promotionCode: productBD.promotionCode,
        description: JSON.parse(productBD.description as string), // it's a json,
        nft_expiration: productBD.expirationDate ? new Date(productBD.expirationDate) : undefined,
        tags: productBD.tags.map(item => ({tag: item})),
        promotions:
          productBD.promotions != ''
            ? JSON.parse(productBD.promotions).map((item: any, index: number) => ({
                title: item.name,
                description: item.description,
                expiration_date: new Date(item.expiration_date),
                image: item.image,
                promotionCode: productBD.promotionCode,
              }))
            : '[]',
        devolution_map: rppPost.toString(),
        image: productBD.image,
      };
    }
    return {
      eligibleQuantity: 0,
      tags: [],
      promotions: [{expiration_date: twoMonthFromNow}],
      nft_expiration: twoMonthFromNow,
    };
  };

  const {
    control,
    register,
    setValue,
    reset,
    formState: {errors},
    handleSubmit,
  } = useForm({
    defaultValues: {
      ...getInitialValues(null),
    },
    resolver: yupResolver(ProductSchema),
  });

  const {fields: promotionFields, remove: promotionRemove} = useFieldArray({
    control,
    name: 'promotions', // unique name for your Field Array
  });

  if (productId && !product) return null;
  let formTitle = t('Create Product');
  let formSubmitText = t('Create Product');
  if (productId) {
    formTitle = t('Edit Product');
    formSubmitText = t('Save');
  }

  const handleUploadCSVDevolution = () => {
    (inputFileRefDevolution.current! as any).click();
  };
  const handleUploadCSVTags = () => {
    (inputFileRefTags.current! as any).click();
  };

  const handleFileChangeDevolution = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files) {
        if (e.target.files[0].type === 'text/csv') {
          setDevolutionMap({name: e.target.files[0].name, file: e.target.files[0]});
          const csvFile = e.target.files[0];
          csvFile.text().then(txt => {
            const csvParsed = txt.split('\n');
            const csvItems: {latitude: number; longitude: number; address: string; name: string}[] = [];
            for (let i = 1; i < csvParsed.length; i++) {
              const csvRow = csvParsed[i].split(';');
              csvRow.length == 4 && csvItems.push({latitude: Number.parseFloat(csvRow[2]), longitude: Number.parseFloat(csvRow[3]), address: csvRow[1], name: csvRow[0]});
            }
            setreturnPoints(csvItems);
            if (csvItems.length == 1) {
              setStartCords({coords: {lat: csvItems[0].latitude, lng: csvItems[0].longitude}});
              setMapZoom(undefined);
              setMapZoom(15);
            } else if (csvItems.length == 0) {
              setStartCords({coords: {lat: 41.8, lng: 1}});
              setMapZoom(4);
            } else {
              setMapZoom(undefined);
            }
          });
        }
      }
    } catch {
      setreturnPoints(returnPoints);
    }
  };

  const handleChangeAllTagsChecked = (isSelected: boolean) => {
    setAllTagsCheckbox(isSelected);
    if (isSelected) {
      if (checkedState.every(value => value === false)) {
        const newState = checkedState.map(() => true);
        setCheckedState(newState);
        handleChangeChecked(0, undefined, undefined, false);
      } else {
        const newState = checkedState.map(() => false);
        setCheckedState(newState);
        setSelectedTag(null);
      }
    } else {
      const newState = checkedState.map(() => false);
      setCheckedState(newState);
      setSelectedTag(null);
    }
  };

  const handleChangeChecked = (id: number, list?: any, checkedStateNew?: boolean[], updateCheckState = true) => {
    const modTagListdata = list || tagListdata;
    let updatedCheckedState = [];
    if (checkedStateNew) {
      updatedCheckedState = checkedStateNew;
    } else {
      updatedCheckedState = checkedState.map((item, index) => (index === id ? !item : item));
    }

    if (updateCheckState) {
      setAllTagsCheckbox(updatedCheckedState.some(value => value === true));

      setCheckedState(updatedCheckedState);
    }

    if (product && updatedCheckedState[id] == true && updatedCheckedState.filter(element => element === true).length == 1) {
      setreturnPoints(
        modTagListdata[id].returnPoints.map((item: any) => ({
          latitude: Number.parseFloat(item.location[0].coordinates[0].toString()),
          longitude: Number.parseFloat(item.location[0].coordinates[1].toString()),
          address: item.address,
          name: item.name,
        }))
      );
      const prodTemp = {
        _id: product._id,
        defaultCertificates: product.defaultCertificates,
        uploadedCertificates: product.uploadedCertificates,
        uploadedPromoImages: modTagListdata[id].promotions.map((item: any) => item.image),
        uploadedCertificateNames: product.uploadedCertificateNames,
        name: JSON.parse(product.name as string), // it's a json
        units: modTagListdata.length,
        batch: product.batch,
        promotionCode: product.promotionCode,
        eligibleQuantity: modTagListdata.length,
        description: JSON.parse(product.description as string), // it's a json
        nft_expiration: modTagListdata[id].expirationDate ? new Date(modTagListdata[id].expirationDate) : new Date(),
        tags: product.tags.map(item => item),
        promotions: modTagListdata[id].promotions.map((item: any) => ({
          title: item.name,
          description: item.description ?? '',
          expiration_date: new Date(item.expiration_date),
          image: item.image,
        })),
        returnPoints: modTagListdata[id].returnPoints
          .map((item: any) => ({
            latitude: Number.parseFloat(item.location[0].coordinates[0].toString()),
            longitude: Number.parseFloat(item.location[0].coordinates[1].toString()),
            address: item.address,
            name: item.name,
          }))
          .toString(),
        image: product.image,
      };
      reset(prodTemp);
      if (prodTemp.tags[id]) {
        product.promotions = JSON.parse(prodTemp.tags[id]).promotions;
        product.uploadedPromoImages = prodTemp.uploadedPromoImages;
        setPromoImages(convertImageUrlToImageType(product.uploadedPromoImages));
      }
      if (prodTemp.returnPoints.length > 0) {
        if (prodTemp.returnPoints.length == 1) {
          setStartCords({coords: {lat: prodTemp.returnPoints[0].latitude, lng: prodTemp.returnPoints[0].longitude}});
          setMapZoom(undefined);
          setMapZoom(15);
        } else {
          setMapZoom(undefined);
        }
      } else {
        setMapZoom(4);
      }
    }
    setcanEditTag(updatedCheckedState.some(element => element === true));
    setSelectedTag(modTagListdata[id]);
  };

  const handleFileChangeTags = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files) {
        if (e.target.files[0].type === 'text/csv') {
          const csvFile = e.target.files[0];
          csvFile.text().then(txt => {
            const csvParsedTags = txt.split('\n');
            const csvTags: {tags: string}[] = [];
            for (let i = 1; i < csvParsedTags.length; i++) {
              const csvRow = csvParsedTags[i].split(';');
              if (isValidTag(csvRow[0].trim())) {
                if (nameTagList.findIndex(a => JSON.parse(a.tags).code == csvRow[0].trim()) == -1) csvTags.push({tags: csvRow[0].trim()});
                else {
                  sharedToast({
                    title: t('Warning!'),
                    description: t('Some tags are already in the list, they have not been imported'),
                    isClosable: true,
                    status: TOAST_STATUS.WARNING,
                  });
                }
              }
            }
            checkIfProductTagsAlreadyExist(csvTags.map(a => a.tags)).then(function(exists: Record<string, boolean>) {
              csvTags.map((tag, index) => {
                if (exists[tag.tags]) {
                  sharedToast({
                    title: t('Warning!'),
                    description: t('Some tags already exist, they have not been imported'),
                    isClosable: true,
                    status: TOAST_STATUS.WARNING,
                  });
                  csvTags.splice(index, 1);
                }
              });
              if (csvTags.length > 0) {
                const tempTag = csvTags.map(
                  (tempTagSel: any) =>
                    ({
                      _id: '',
                      created: '',
                      modified: '',
                      code: tempTagSel.tags,
                      productId: '',
                      promotions: [],
                      owner: '',
                      returnPoints: [],
                      active: true,
                      expirationDate: twoMonthFromNow,
                      claimDate: new Date(),
                    } as ITag)
                );

                const tagsFiltered: ITag[] = [];
                const tagsFilteredList: {tags: string}[] = [];
                nameTagList.concat(tempTag.map((tagsel: any) => ({tags: JSON.stringify(tagsel)}))).map(x => {
                  if (!tagsFiltered.find(y => y.code == x.tags)) {
                    tagsFilteredList.push({tags: x.tags});
                    tagsFiltered.push(JSON.parse(x.tags));
                  }
                });
                setNameTagList(tagsFilteredList);
                setTagListData(tagsFiltered);

                const checkstatetemp: boolean[] = [];
                nameTagList.concat(tempTag.map((selectedtagfromlist: any) => ({tags: JSON.stringify(selectedtagfromlist)}))).map(tag => {
                  if (tempTag.filter(tagNew => tagNew.code == JSON.parse(tag.tags).code).length > 0) {
                    setSelectedTag(JSON.parse(tag.tags));
                    checkstatetemp.push(true);
                  } else checkstatetemp.push(false);
                });
                setCheckedState(checkstatetemp);
                setcanEditTag(checkstatetemp.some(element => element === true));
                handleChangeChecked(
                  checkstatetemp.findIndex(element => element === true),
                  tagsFiltered,
                  checkstatetemp
                );
              }
            });
          });
        }
      }
    } catch {
      setTagDataRows(nameTagList);
      setNameTagList(nameTagList);
    }
  };

  // Comprueba si un tag de producto es valido
  const isValidTag = function(tag: string): boolean {
    try {
      ProductTagSchema.validateSync(tag);
      return true;
    } catch (error) {
      sharedToast({
        title: t('Warning!'),
        description: t('Some tags are not valid, they have not been imported'),
        isClosable: true,
        status: TOAST_STATUS.WARNING,
      });
      return false;
    }
  };

  const removeTag = (tagId: string, i: number) => {
    removeTagDialog?.showConfirmationDialog(
      t('Delete tag'),
      <View>
        <Text>{t('Are you sure you want to delete the tag permanently? This operation cannot be undone')}</Text>
      </View>,
      {
        label: 'Delete',
        onPress: () => {
          tagService
            .remove({id: tagId})
            .response(response => {
              console.debug('Remove completed', tagId);
              nameTagList.splice(i, 1);
              setTagDataRows(nameTagList);
              sharedToast({
                title: t('Tag removed'),
                description: '',
              });
            })
            .error(error => {
              console.error(error);
              sharedToast({
                title: t('Error on remove'),
                description: t('Something went wrong trying to remove the tag'),
                status: TOAST_STATUS.ERROR,
                variant: TOAST_VARIANT.SUBTLE,
              });
            })
            .finally(() => {
              removeTagDialog.hideConfirmationDialog();
            });
        },
      }
    );
  };

  const multilanguageTabError: MultilanguageTab = {
    tabProps: {
      bgColor: 'error.100',
      borderColor: 'error.700',
    },
    tabHoverProps: {
      bgColor: 'error.200',
      borderColor: 'error.700',
    },
  };

  const multilanguageTabErrorSelected: MultilanguageTab = {
    tabProps: {
      bgColor: 'error.300',
      borderColor: 'error.700',
    },
    tabHoverProps: {
      bgColor: 'error.300',
      borderColor: 'error.700',
    },
  };

  const multilanguageValues = (error: any, field: any, required = true) =>
    ({
      languages: AVAILABLE_LANGUAGES.map(lang => ({
        codeName: lang,
        displayName: lang.toUpperCase(),
        tab: required && DEFAULT_LANGUAGE == lang && error ? multilanguageTabError : undefined,
        tabSelected: required && DEFAULT_LANGUAGE == lang && error ? multilanguageTabErrorSelected : undefined,
      })),
      tabBar: {paddingLeft: 2, paddingRight: 2},
      tab: {tabProps: {rounded: 'lg', h: 7}, tabHoverProps: {rounded: 'lg', h: 7}},
      tabSelected: {tabProps: {rounded: 'lg', h: 8}, tabHoverProps: {rounded: 'lg', h: 8}, textProps: {bold: true}},
      setSelectedTab: AVAILABLE_LANGUAGES.findIndex(lang => lang == DEFAULT_LANGUAGE),
      value: field.value,
      onChange: (value: any) => {
        field.onChange(value);
      },
    } as ComponentProps<typeof MultilanguageField>);

  return (
    <PageLayout title={formTitle}>
      <View px={6} py={3} overflowX="auto">
        <HStack alignItems="baseline">
          <Icon as={AntDesign} name="filetext1" size={4} mr="1" color="muted.400" />
          <Heading size="xs">{t('Data')}</Heading>
        </HStack>
        {/* DATA: */}
        <Stack direction={['column', 'column', 'row']} space={[0, 10, 100]}>
          <Stack direction="column" space={1} flex="4">
            <Stack direction={['column', 'row', 'row']} space={5} flex={2} mb={3} alignItems="end">
              <FormControl isRequired isInvalid={'name' in errors} key="name" flex={1}>
                <FormControl.Label _text={{bold: true}}>{t('Name')}</FormControl.Label>
                <Controller
                  name="name"
                  control={control}
                  render={({field}) => <MultilanguageField {...multilanguageValues(errors.name, field)} inputProps={{type: 'text', isDisabled: true}} />}
                />
                {errors.name && <FormControl.ErrorMessage>{errors.name?.message}</FormControl.ErrorMessage>}
              </FormControl>

              <FormControl isRequired isInvalid={'eligibleQuantity' in errors} key="eligibleQuantity" flex={1}>
                <FormControl.Label _text={{bold: true}}>{t('Units available')}</FormControl.Label>
                <Controller name="eligibleQuantity" control={control} render={({field}) => <Input type="text" isDisabled {...field} />} />
                {errors.eligibleQuantity && <FormControl.ErrorMessage>{errors.eligibleQuantity?.message}</FormControl.ErrorMessage>}
              </FormControl>

              <FormControl isRequired isInvalid={'batch' in errors} key="batch" flex={1}>
                <FormControl.Label _text={{bold: true}}>{t('Batch')}</FormControl.Label>
                <Controller name="batch" control={control} render={({field}) => <Input type="text" isDisabled={productId !== undefined} {...field} />} />
                {errors.batch && <FormControl.ErrorMessage>{errors.batch?.message}</FormControl.ErrorMessage>}
              </FormControl>
            </Stack>

            <Stack>
              <FormControl isRequired isInvalid={'description' in errors} key="description" mb={6}>
                <FormControl.Label _text={{bold: true}}>{t('Description')}</FormControl.Label>
                <Controller
                  name="description"
                  control={control}
                  render={({field}) => (
                    <MultilanguageField {...multilanguageValues(errors.description, field)} inputProps={{type: 'text', isDisabled: true, multiline: true, numberOfLines: 3}} />
                  )}
                />
                {errors.description && <FormControl.ErrorMessage>{errors.description?.message}</FormControl.ErrorMessage>}
              </FormControl>
            </Stack>
          </Stack>
          <View flex={3}>
            <ImageUploading value={images} onChange={onImageChange} maxNumber={maxNumber} dataURLKey="data_url">
              {({imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps}) => (
                // write your building UI
                <View bgColor="gray.100" p={4} borderRadius={4}>
                  {imageList.length === 0 && !productId ? (
                    <div {...dragProps}>
                      <View bgColor="secondary.100" p={5} py={8} borderStyle="dashed" borderWidth={1} borderColor="primary.400">
                        <Center>
                          <VStack space={2} alignItems="center">
                            <Icon as={MaterialCommunityIcons} name="folder-multiple" size="10" color="primary.500" />
                            <Text bold fontSize="xs">
                              {t('Drop your product image here')}
                            </Text>
                          </VStack>
                          <HStack width="100%" alignItems="center" space={4} my={4}>
                            <View height={0} borderBottomWidth={1} borderColor="primary.500" flex={1} />
                            <Text color="primary.500">{t('or')}</Text>
                            <View height={0} borderBottomWidth={1} borderColor="primary.500" flex={1} />
                          </HStack>
                          <Button onPress={onImageUpload} {...dragProps}>
                            {t('Search file')}
                          </Button>
                          {/* {errors && ( */}
                          {/*  <View> */}
                          {/*    {errors.maxNumber && <Text>Number of selected images exceed maxNumber</Text>} */}
                          {/*    {errors.acceptType && <Text>Your selected file type is not allow</Text>} */}
                          {/*    {errors.maxFileSize && <Text>Selected file size exceed maxFileSize</Text>} */}
                          {/*    {errors.resolution && <Text>Selected file is not match your desired resolution</Text>} */}
                          {/*  </View> */}
                          {/* )} */}
                        </Center>
                      </View>
                    </div>
                  ) : (
                    imageList.map((image, index) => (
                      <VStack key={index} space={4} alignItems="center">
                        <img src={image.data_url} alt="" width="200" />
                        <View>
                          <Button onPress={() => onImageRemove(index)} width="200">
                            {t('Remove')}
                          </Button>
                        </View>
                      </VStack>
                    ))
                  )}
                  {productId && (
                    <VStack space={4} alignItems="center">
                      <img src={product?.image} alt="" width="200" />
                    </VStack>
                  )}
                </View>
              )}
            </ImageUploading>
          </View>
        </Stack>
        {/* CERTIFICADOS: */}
        <HStack alignItems="baseline" mt={8} mb={2} flex={1}>
          <Icon as={AntDesign} name="filetext1" size={4} mr="1" color="muted.400" />
          <Heading size="xs">{t('Certificates')}</Heading>
        </HStack>
        <VStack>
          <Input {...register('certificates')} display="none" />
          <Input {...register('uploadedCertificateNames')} display="none" />
          <Input {...register('uploadedCertificates')} display="none" />

          <Certificates
            isDisabled={productId !== undefined}
            selectedCertificates={product ? product.defaultCertificates : []}
            uploadedCertificates={
              product
                ? product.uploadedCertificates.map((cert, index) => ({
                    name: product.uploadedCertificateNames[index],
                    url: cert,
                  }))
                : []
            }
            handleDefaultCertificates={certificates => {
              setValue('certificates', certificates.toString());
            }}
            handleUploadCertificates={certificates => {
              setValue(
                'uploadedCertificateNames',
                certificates.map(item => item.name)
              );
              setValue(
                'uploadedCertificates',
                certificates.map(item => item.file)
              );
            }}
          />
        </VStack>
        <View height={0} borderWidth={1} borderColor="gray.300" my={10} />
        {/* BOTTOM PAGE: */}
        <Stack space={[0, 10, 100]}>
          <Stack direction={['column', 'column', 'column', 'column', 'column', 'row']} flex={2} mb={3} space={[8, 8, 8, 8, 8, 40]}>
            {/* TAG LIST: */}
            <Stack flex={2}>
              <HStack alignItems="baseline">
                <Heading fontSize="24">{t('Tag List')}</Heading>
              </HStack>
              <FormControl isInvalid={errors.tags instanceof Array}>
                <FormControl.Label>
                  <Button
                    variant="outline"
                    shadow="2"
                    marginTop={2}
                    borderRadius={10}
                    leftIcon={<Icon as={Ionicons} size={5} name="add" />}
                    onPress={() => handleUploadCSVTags()}>
                    {t('Add Tag')}
                  </Button>
                  <input
                    type="file"
                    ref={inputFileRefTags}
                    hidden
                    onChange={event => {
                      handleFileChangeTags(event);
                    }}
                  />
                </FormControl.Label>
              </FormControl>
              <Stack marginTop={2}>
                <table style={{color: 'black', borderCollapse: 'collapse'}}>
                  <thead>
                    <tr>
                      <th style={{textAlign: 'left', padding: '10px', borderBottom: '1px solid #787389'}}>
                        <HStack space={4}>
                          <Checkbox
                            value=""
                            isChecked={allTagsCheckbox}
                            onChange={handleChangeAllTagsChecked}
                            icon={
                              checkedState.every(value => value === true) ? (
                                <Icon as={MaterialCommunityIcons} name="check" opacity={1} />
                              ) : (
                                <Icon as={MaterialCommunityIcons} name="minus" opacity={1} />
                              )
                            }
                          />
                          <Text fontSize="18" fontWeight={600} color="#787389">
                            {t('Tag ID')}
                          </Text>
                        </HStack>
                      </th>
                      <th style={{padding: '10px', borderBottom: '1px solid #787389'}}>
                        <Text fontSize="18" fontWeight={600} bold color="#787389">
                          {t('Status')}
                        </Text>
                      </th>
                      <th style={{padding: '10px', borderBottom: '1px solid #787389'}}>
                        <Text fontSize="18" fontWeight={600} bold color="#787389">
                          {t('Actions')}
                        </Text>
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{padding: '10px'}}>
                    {tagListdata.map((tag, i) => (
                      <tr key={i}>
                        <td style={{padding: '15px'}}>
                          <HStack space={4} alignItems="center">
                            <input type="checkbox" id={`tag-list-${i}`} name={tag.code} value={tag._id} checked={checkedState[i]} onChange={() => handleChangeChecked(i)} />
                            <Text color="#787389" maxWidth={['20vw', '30vw', '100vw', '100vw', '100vw', '19vw']}>
                              {tag.code}
                            </Text>
                          </HStack>
                        </td>
                        <td style={{textAlign: 'center', padding: '15px'}}>
                          <Icon as={MaterialCommunityIcons} name="circle" size={4} color={statusColor(tag)} />
                        </td>
                        <td style={{display: 'flex', alignItems: 'center', alignContent: 'center', padding: '15px'}}>
                          <div style={{margin: 'auto'}}>
                            <HStack space={4} alignItems="center">
                              <Pressable
                                onPress={() => {
                                  if (tag._id) {
                                    removeTag(tag._id, i);
                                  } else {
                                    nameTagList.splice(i, 1);
                                    setTagDataRows(nameTagList);
                                  }
                                }}>
                                <Icon as={AntDesign} name="delete" size={4} color="#0059A7BD" />
                              </Pressable>
                            </HStack>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Stack>
            </Stack>
            {/* FORMS TAGS */}
            {canEditTag && selectedTag && (
              <Stack direction="column" flex={3}>
                <Stack direction="column">
                  <Stack space={6} direction="row" zIndex={1}>
                    <Stack>
                      <FormControl isRequired={false} isInvalid={'nft_expiration' in errors} key="nft_expiration" flex={1}>
                        <FormControl.Label _text={{bold: true}}>{t('NFT expiration date')}</FormControl.Label>
                        <Controller
                          name="nft_expiration"
                          control={control}
                          render={({field}) => (
                            <DatePicker
                              dateFormat="dd/MM/yyy"
                              locale="en"
                              onChange={field.onChange}
                              date={field.value}
                              customInput={<Input type="text" InputRightElement={<Icon as={AntDesign} name="calendar" size={4} color="primary.500" mr={2} />} />}
                            />
                          )}
                        />
                        {errors.nft_expiration && <FormControl.ErrorMessage>{errors.nft_expiration?.message}</FormControl.ErrorMessage>}
                      </FormControl>
                    </Stack>
                    <Stack>
                      <FormControl isInvalid={'devolution_map' in errors} key="devolution_map">
                        <HStack space={4}>
                          <VStack style={{flex: 1}}>
                            <FormControl.Label _text={{bold: true}} ml="4">
                              {t('Devolution map')}
                            </FormControl.Label>
                            <Controller
                              name="devolution_map"
                              control={control}
                              render={({field}) => <Input width="205" ml="4" type="text" isReadOnly value={devolutionMap?.name} />}
                            />
                            {errors.devolution_map && <FormControl.ErrorMessage>{errors.devolution_map?.message}</FormControl.ErrorMessage>}
                          </VStack>
                          <VStack alignItems="center">
                            <FormControl.Label _text={{bold: true}} ml="4">
                              {t(' ')}
                            </FormControl.Label>
                            <Button
                              variant="outline"
                              shadow="2"
                              borderRadius={10}
                              isDisabled={productId == undefined}
                              _text={{color: 'black'}}
                              leftIcon={<Icon as={Ionicons} size={5} name="attach" color="black" />}
                              onPress={() => handleUploadCSVDevolution()}>
                              {t('Upload')}
                            </Button>
                            <input
                              type="file"
                              ref={inputFileRefDevolution}
                              hidden
                              onChange={event => {
                                handleFileChangeDevolution(event);
                              }}
                            />
                          </VStack>
                        </HStack>
                      </FormControl>
                    </Stack>
                  </Stack>
                  <Box
                    marginTop={7}
                    rounded="lg"
                    overflow="hidden"
                    marginRight={6}
                    backgroundColor="transparent"
                    shadow="2"
                    borderWidth="0"
                    flex={1}
                    width="fill"
                    minHeight={210}>
                    <Map
                      apikey={Config.googleMaps.apiKey}
                      zoom={mapZoom}
                      center={startCords}
                      iconsHidden
                      locations={returnPoints?.map(rtp => ({
                        coords: {
                          lat: rtp.latitude,
                          lng: rtp.longitude,
                        },
                        icon: {
                          symbol: {
                            path: Markers.Bin,
                            fillColor: '#0059A7',
                            fillOpacity: 10,
                            scale: 1,
                            strokeColor: 'transparent',
                          },
                          anchor: {
                            x: 1,
                            y: 1,
                          },
                        },
                      }))}
                    />
                  </Box>
                </Stack>
                <Stack paddingTop={5} direction="column">
                  {/* PROMOTIONS */}
                  <HStack alignItems="baseline">
                    <Icon as={AntDesign} name="filetext1" size={4} mr="1" color="muted.400" />
                    <Heading size="xs">{t('Promotions')}</Heading>
                  </HStack>
                  {promotionFields.map((promotionField, index) => (
                    <Stack direction="row" padding={10}>
                      <Stack direction="column">
                        <Stack direction="row" alignItems="end">
                          <Stack width="205">
                            <FormControl isRequired isInvalid={errors.promotions instanceof Array && errors.promotions[index]} key={`title.${promotionField.id}`} flex={1}>
                              <FormControl.Label _text={{bold: true}} paddingTop={3} paddingBottom={2}>
                                {t('Title')}
                              </FormControl.Label>
                              <Controller
                                name={`promotions.${index}.title`}
                                control={control}
                                render={({field: field1}) => (
                                  <MultilanguageField
                                    {...multilanguageValues(
                                      errors.promotions instanceof Array && errors.promotions[index]?.title ? errors.promotions[index]?.title : undefined,
                                      field1
                                    )}
                                    inputProps={{type: 'text', ref: field1.ref}}
                                  />
                                )}
                              />
                              <View h={6}>
                                {errors.promotions instanceof Array && errors.promotions[index]?.title && (
                                  <FormControl.ErrorMessage>{errors.promotions[index]?.title?.message}</FormControl.ErrorMessage>
                                )}
                              </View>
                            </FormControl>
                          </Stack>
                          <Stack paddingLeft={10}>
                            <FormControl
                              isRequired
                              isInvalid={errors.promotions instanceof Array && errors.promotions[index]}
                              key={`expiration_date.${promotionField.id}`}
                              flex={1}>
                              <FormControl.Label _text={{bold: true}} paddingTop={3} paddingBottom={2}>
                                {t('Expiration Date')}
                              </FormControl.Label>
                              <Controller
                                name={`promotions.${index}.expiration_date`}
                                control={control}
                                render={({field: field2}) => (
                                  <DatePicker
                                    dateFormat="dd/MM/yyy"
                                    locale="en"
                                    onChange={field2.onChange}
                                    date={field2.value}
                                    customInput={
                                      <Input
                                        type="text"
                                        isDisabled={productId == undefined}
                                        InputRightElement={<Icon as={AntDesign} name="calendar" size={4} color="primary.500" mr={2} />}
                                      />
                                    }
                                  />
                                )}
                              />
                              <View h={6}>
                                {errors.promotions instanceof Array && errors.promotions[index]?.expiration_date && (
                                  <FormControl.ErrorMessage>{errors.promotions[index]?.expiration_date?.message}</FormControl.ErrorMessage>
                                )}
                              </View>
                            </FormControl>
                          </Stack>
                        </Stack>
                        <View>
                          <FormControl height="fill" isRequired={false} key={`description.${index}`}>
                            <FormControl.Label paddingTop={3} paddingBottom={2} _text={{bold: true}}>
                              {t('Description')}
                            </FormControl.Label>
                            <Controller
                              name={`promotions.${index}.description`}
                              control={control}
                              render={({field: field3}) => (
                                <MultilanguageField
                                  {...multilanguageValues(
                                    errors.promotions instanceof Array && errors.promotions[index]?.description ? errors.promotions[index]?.description : undefined,
                                    field3,
                                    false
                                  )}
                                  inputProps={{type: 'text', multiline: true, numberOfLines: 3}}
                                />
                              )}
                            />
                          </FormControl>
                        </View>

                        <Stack direction="row" paddingTop={10}>
                          <FormControl isInvalid={'promotionCode' in errors} key="promotionCode" flex={1 / 2}>
                            <FormControl.Label _text={{bold: true}}>{t('Promotion Code')}</FormControl.Label>
                            <Controller name="promotionCode" control={control} render={({field}) => <Input type="text" isDisabled={productId !== undefined} {...field} />} />
                            <View h={6}>{errors.promotionCode && <FormControl.ErrorMessage>{errors.promotionCode?.message}</FormControl.ErrorMessage>}</View>
                          </FormControl>
                        </Stack>
                      </Stack>
                      <Stack paddingLeft={10} paddingTop={3}>
                        <View flex={2} flexDirection="row" justifyContent="top" alignItems="center">
                          <ImageUploading
                            value={promoImages[index]}
                            onChange={imageList2 => onPromoImageChange(imageList2, index)}
                            maxNumber={maxNumber}
                            dataURLKey="data_url">
                            {({imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps}) => (
                              // write your building UI
                              <View bgColor="gray.100" p={4} borderRadius={4}>
                                {imageList.length === 0 ? (
                                  <div {...dragProps}>
                                    <View bgColor="secondary.100" p={4} borderStyle="dashed" borderWidth={1}
borderColor="primary.400">
                                      <Center>
                                        <VStack space={2} alignItems="center">
                                          <Icon as={MaterialCommunityIcons} name="folder-multiple" size="10" color="primary.500" />
                                          <Text bold fontSize="xs">
                                            {t('Drop your product image here')}
                                          </Text>
                                        </VStack>
                                        <HStack width="100%" alignItems="center" space={4} my={4}>
                                          <View height={0} borderBottomWidth={1} borderColor="primary.500" flex={1} />
                                          <Text color="primary.500">o</Text>
                                          <View height={0} borderBottomWidth={1} borderColor="primary.500" flex={1} />
                                        </HStack>
                                        <Button onPress={onImageUpload} {...dragProps}>
                                          {t('Search file')}
                                        </Button>
                                        {/* {errors && ( */}
                                        {/*  <View> */}
                                        {/*    {errors.maxNumber && <Text>Number of selected images exceed maxNumber</Text>} */}
                                        {/*    {errors.acceptType && <Text>Your selected file type is not allow</Text>} */}
                                        {/*    {errors.maxFileSize && <Text>Selected file size exceed maxFileSize</Text>} */}
                                        {/*    {errors.resolution && <Text>Selected file is not match your desired resolution</Text>} */}
                                        {/*  </View> */}
                                        {/* )} */}
                                      </Center>
                                    </View>
                                  </div>
                                ) : (
                                  imageList.map((image, index2) => (
                                    <VStack key={index2} space={4} alignItems="center">
                                      <img src={image.data_url} alt="" height={100} />
                                      <View>
                                        <Button onPress={() => onImageRemove(index2)} width="200">
                                          {t('Remove')}
                                        </Button>
                                      </View>
                                    </VStack>
                                  ))
                                )}
                              </View>
                            )}
                          </ImageUploading>
                          <View alignSelf="center" paddingLeft={1}>
                            {productId && (
                              <Icon
                                as={AntDesign}
                                name="delete"
                                size={17}
                                color="primary.500"
                                onPress={() => {
                                  promotionRemove(index);
                                  handleRemoveImage(index);
                                }}
                              />
                            )}
                          </View>
                        </View>
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>
        {productId && canEditTag && selectedTag && (
          <HStack alignSelf="flex-end" space={2}>
            <Button
              onPress={handleSubmit(submitFormPreview)}
              isLoading={submittingPreview}
              isLoadingText={t('Loading preview') as string}
              style={{width: 'auto'}}
              variant="outline"
              borderRadius={5}
              leftIcon={<Icon as={Ionicons} name="eye-outline" />}>
              <HStack justifyContent="center" alignItems="center" space={2}>
                <Text>{t('Preview')}</Text>
                <Menu
                  trigger={triggerProps => (
                    <Button accessibilityLabel="More options menu" {...triggerProps} variant="outline" h={6}>
                      <Text>{previewLanguage.toUpperCase()}</Text>
                    </Button>
                  )}>
                  {AVAILABLE_LANGUAGES.map(lang => (
                    <Menu.Item onPress={() => setPreviewLanguage(lang)}>{lang.toUpperCase()}</Menu.Item>
                  ))}
                </Menu>
              </HStack>
            </Button>
            <Button width={20} onPress={handleSubmit(submitFormCreate)} isLoading={submittingCreate} isLoadingText={t('Creating product') as string}>
              {formSubmitText}
            </Button>
          </HStack>
        )}
      </View>
    </PageLayout>
  );
};

const ErrorBoundary = () => {
  const {t} = useTranslation();
  const error = useRouteError() as any;
  const axError = error as AxiosError<any>;
  const navigate = useNavigate();
  return (
    <PageLayout>
      <View style={{flex: 1, paddingHorizontal: 20, paddingVertical: 10}}>
        <VStack>
          <Heading>Something went wrong</Heading>
          <Text>{error.statusText}</Text>
          <Text>{axError && ((axError.response?.data!.message as unknown) as string)}</Text>
          <Button onPress={() => navigate(-1)} width="auto">
            {t('Go back')}
          </Button>
        </VStack>
      </View>
    </PageLayout>
  );
};

export default Object.assign(ModifyProductPage, {
  ErrorBoundary,
});
