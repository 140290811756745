import {Heading, Hidden, View} from 'native-base';
import {useRecoilState} from 'recoil';
import {loggedUser} from '@bri/shared-components';

type Props = {
  title?: any;
  children: any;
};
export const PageLayout = (props: Props) => {
  const [user] = useRecoilState(loggedUser);

  return (
    <div className="outlet" style={{zIndex: -1}}>
      <View flex={1} my={3} px={6} py={2.5}>
        {/* Aquest View es per a que quan es fa pantalla petita i apareix la barra superior, el contingut baixi i es vegi completament */}
        {!user && (
          <Hidden from="lg">
            <View mt={20} flex={1}>
              {' '}
            </View>
          </Hidden>
        )}
        {props.title && (
          <Heading fontSize="2xl" mb={5}>
            {props.title}
          </Heading>
        )}
        {props.children}
      </View>
    </div>
  );
};
