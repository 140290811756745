import {useTranslation} from 'react-i18next';
import {IProduct} from '@bri/rotimpres-core';
import React, {useEffect, useState} from 'react';
import {CustomDataTable, DataRowRequiredData, loggedUser} from '@bri/shared-components';
import {Button, Heading, HStack, Icon, Pressable, Spacer, Text, VStack} from 'native-base';
import {TableColumn, TableStyles} from 'react-data-table-component';
import {AntDesign} from '@native-base/icons';
import {useNavigate} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {SortCriteria} from '@bri/shared-core';
import {useProductsService} from '../services/ProductsService';
import {PageLayout} from '../components/PageLayout';
import {getCurrentLanguage, getMultilanguageTranslation} from '../utils/languageUtils';

type DataRow = DataRowRequiredData & IProduct;

export default function ProductsListPage() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const productService = useProductsService();
  const [products, setProducts] = useState<DataRow[]>([]);
  const [loading, setLoading] = useState(false);
  const [user] = useRecoilState(loggedUser);
  const [sort, setSort] = useState<SortCriteria>({});

  useEffect(() => {
    const fetchData = async () => {
      productService
        .list({owner: user?._id || '', sort})
        .apiResponse(() => {
          setLoading(false);
        })
        .response((resp: any) => {
          setProducts(resp.products as any);
        })
        .error((err: any) => console.log(err));
    };
    fetchData().catch(console.error);
  }, [sort]);

  if (loading) return null;

  // const deleteFunction = (id: string) => {
  //   // TODO
  // };

  const customStyle = {
    color: '#787389',
    fontSize: '12px',
  };

  const nameSortField = `name.${getCurrentLanguage()}`;

  const columns: TableColumn<DataRow>[] = [
    {
      name: t('Product name'),
      selector: (row: DataRow) => getMultilanguageTranslation(row.name),
      sortable: true,
      sortField: nameSortField,
      grow: 2,
      cell: item => (
        <VStack space="md" marginTop={5} flex={1}>
          <Heading fontSize="14" justifyItems="self-start">
            {getMultilanguageTranslation(item.name)}
          </Heading>
          <Text fontSize="12" fontWeight="normal" color="#787389" numberOfLines={2}>
            {getMultilanguageTranslation(item.description)}
          </Text>
        </VStack>
      ),
    },
    {
      name: t('Batch'),
      center: true,
      style: customStyle,
      selector: (row: DataRow) => row.batch,
      sortable: true,
    },
    {
      name: t('Promotions'),
      center: true,
      style: customStyle,
      selector: (row: DataRow) => row.nPromotions,
      sortable: true,
    },
    {
      name: t('Tags'),
      center: true,
      style: customStyle,
      selector: (row: DataRow) => row.nTags,
      sortable: true,
    },
    {
      name: t('Actions'),
      center: true,
      cell: item => (
        <HStack space={4} alignItems="center">
          {/* <Pressable
            onPress={() => {
              console.log('product id: ', item._id);
              navigate(`/product/${item._id}`);
            }}>
            <Icon as={AntDesign} name="edit" size={4} color="#0059A7BD" />
          </Pressable>

          <Pressable
            onPress={() => {
              deleteFunction(item._id);
            }}>
            <Icon as={AntDesign} name="delete" size={4} color="#0059A7BD" />
          </Pressable> */}

          <Pressable
            onPress={() => {
              navigate(`/product/${item._id}`);
            }}>
            <Icon as={AntDesign} name="eyeo" size={4} color="primary.400" />
          </Pressable>
        </HStack>
      ),
    },
  ];

  const customStyles: TableStyles = {
    tableWrapper: {
      style: {
        padding: 4,
      },
    },
    table: {
      style: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
      },
    },
    header: {
      style: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
      },
    },
    headCells: {
      style: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
        fontSize: 14,
      },
    },
    pagination: {
      style: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
      },
    },
  };

  return (
    <PageLayout title={t('Product List')}>
      <VStack space={2} alignItems="center">
        <Button alignSelf="end" marginRight={2} borderRadius={7} shadow={3}
onPress={() => navigate('/product')}>
          <HStack alignItems="center">
            <Icon as={AntDesign} name="plus" color="white" />
            <Spacer size={2} />
            <Text color="white" fontSize={14}>
              {t('Create Product')}
            </Text>
          </HStack>
        </Button>
        <CustomDataTable
          columns={columns}
          data={products}
          export
          sortServer
          showExtensions={false}
          defaultActions={false}
          customStyles={customStyles}
          defaultSortField="Product name"
          pagination
          onSort={(newSort: SortCriteria) => setSort(newSort)}
        />
      </VStack>
    </PageLayout>
  );
}
