import {createBrowserRouter} from 'react-router-dom';
import React from 'react';
import {View} from 'native-base';
import {USER_ACTIONS} from '@bri/rotimpres-core';
import Root from '../pages/root';
import ErrorPage from '../pages/ErrorPage';
import LoginPage from '../pages/LoginPage';
import SamplePage from '../pages/SamplePage';
import ProtectedRoute from './ProtectedRoute';
import TermsAndConditionsPage from '../pages/TermsAndConditionsPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import CookiesPage from '../pages/CookiesPage';
import CookiesConsentPage from '../pages/CookiesConsentPage';
import UnAuthenticated from './UnAuthenticated';
import ListUsersPage from '../pages/ListUsersPage';
import FormUsersPage from '../pages/FormUsersPage';
import FormProductPage from '../pages/FormProductPage';
import ProductsListPage from '../pages/ProductsListPage';
import ModifyProductPage from '../pages/ModifyProductPage';
import ListCustomersPage from '../pages/ListCustomersPage';
import ListUsersTagsPage from '../pages/ListUserTagsPage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    // loader: rootLoader,
    // action: rootAction,
    children: [
      {
        path: '',
        element: <ProtectedRoute action={USER_ACTIONS.USE_APP_ADMIN} redirectPath="/login" />,
        children: [
          {
            path: '',
            element: <SamplePage />,
            errorElement: <SamplePage.ErrorBoundary />,
          },
        ],
      },
      {
        path: '',
        element: <ProtectedRoute action={USER_ACTIONS.CUSTOMER_CRUDL} redirectPath="/login" />,
        children: [
          {
            path: 'listCustomers',
            element: <ListCustomersPage />,
            // errorElement: <SamplePage.ErrorBoundary />,
          },
        ],
      },
      {
        path: '',
        element: <ProtectedRoute action={USER_ACTIONS.ADMIN_LIST_USERS} redirectPath="/login" />,
        children: [
          {
            path: 'listUsers',
            element: <ListUsersPage />,
          },
          {
            path: 'listUsers/:customerId',
            element: <ListUsersPage />,
            // errorElement: <SamplePage.ErrorBoundary />,
          },
        ],
      },
      {
        path: '',
        element: <ProtectedRoute action={USER_ACTIONS.USER_CRUDL} redirectPath="/login" />,
        children: [
          {
            path: 'listCustomerUsers',
            element: <ListUsersPage />,
            // errorElement: <SamplePage.ErrorBoundary />,
          },
          {
            path: 'formUsers/:userId',
            element: <FormUsersPage />,
            // errorElement: <SamplePage.ErrorBoundary />,
          },
          {
            path: 'formUsers',
            element: <FormUsersPage />,
          },
          {
            path: 'listUserTags/:userId',
            element: <ListUsersTagsPage />,
            // errorElement: <SamplePage.ErrorBoundary />,
          },
        ],
      },
      {
        path: '',
        element: <ProtectedRoute action={USER_ACTIONS.PRODUCT_CRUDL} redirectPath="/login" />,
        children: [
          {
            path: 'listProducts',
            element: <ProductsListPage />,
            // errorElement: <SamplePage.ErrorBoundary />,
          },
          {
            path: 'product/:productId',
            element: <ModifyProductPage />,
            errorElement: <ModifyProductPage.ErrorBoundary />,
          },
          {
            path: 'product',
            element: <FormProductPage />,
            errorElement: <FormProductPage.ErrorBoundary />,
          },
        ],
      },
      {
        path: 'login',
        element: (
          <UnAuthenticated>
            <LoginPage />
          </UnAuthenticated>
        ),
      },
      {
        path: 'terminos-y-condiciones',
        element: <TermsAndConditionsPage />,
      },
      {
        path: 'privacyPolicy',
        element: <PrivacyPolicyPage />,
      },
      {
        path: 'cookies',
        element: <CookiesPage />,
      },
      {
        path: 'cookiesConsent',
        element: <CookiesConsentPage />,
      },
      {
        path: '*',
        element: <View>There's nothing here: 404</View>,
      },
    ],
  },
]);
