import {Button, FlatList, HStack, Icon, Image, Input, View, VStack} from 'native-base';
import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AntDesign, Ionicons} from '@native-base/icons';
import {ReactSVG} from 'react-svg';
import {CertificateTypes} from '@bri/rotimpres-core/dist';
import {TOAST_STATUS, TOAST_VARIANT, useSharedToast} from '@bri/shared-components';
import {useProductsService} from '../services/ProductsService';

type Props = {
  handleDefaultCertificates: (certificates: string[]) => void;
  handleUploadCertificates: (certificates: {name: string; file?: any}[]) => void;
  selectedCertificates?: string[];
  uploadedCertificates?: {name: string; file?: any; url?: string}[];
  isDisabled?: boolean;
};

function Certificates(props: Props) {
  const {t} = useTranslation();
  const [certificates, setCertificates] = useState<any | null>(null);
  const [selectedCertificates, setSelectedCertificates] = useState<string[]>(props.selectedCertificates ?? []);
  const [uploadCertificates, setUploadCertificates] = useState<{name: string; file?: any; url?: string}[]>(props.uploadedCertificates ?? []);
  const productService = useProductsService();
  const inputFileRef = useRef(null);
  const sharedToast = useSharedToast();

  const defaultCertificates = [
    {
      type: CertificateTypes.CE,
      text: t('CE Marking'),
      icon: <ReactSVG src="/certificates/CE.svg" />,
    },
    {
      type: CertificateTypes.FSC,
      text: t('FSC'),
      icon: <ReactSVG src="/certificates/FSC.svg" />,
    },
    {
      type: CertificateTypes.FAIRTRADE,
      text: t('Fairtrade'),
      icon: <ReactSVG src="/certificates/Fairtrade.svg" />,
    },
    {
      type: CertificateTypes.CRUELTY_FREE,
      text: t('Cruelty free'),
      icon: <ReactSVG src="/certificates/cruelty_free.svg" />,
    },
    {
      type: CertificateTypes.COSMOS,
      text: t('COSMOS'),
      icon: <ReactSVG src="/certificates/COSMOS.svg" />,
    },
    {
      type: CertificateTypes.OEKO_TEX,
      text: t('Oeko-tex'),
      icon: (
        <View style={{flex: 1, width: 35, height: 23}}>
          <Image src="/certificates/Oeko_tex.png" width={35} height={23} />
        </View>
      ),
    },
    {
      type: CertificateTypes.GOTS,
      text: t('GOTS'),
      icon: <ReactSVG src="/certificates/GOTS.svg" />,
    },
    {
      type: CertificateTypes.VEGAN,
      text: t('Vegan'),
      icon: <ReactSVG src="/certificates/vegan.svg" />,
    },
    {
      type: CertificateTypes.V_LABEL,
      text: t('V-Label'),
      icon: <ReactSVG src="/certificates/V_Label.svg" />,
    },
    {
      type: CertificateTypes.ELS,
      text: t('ELS'),
      icon: <ReactSVG src="/certificates/gluten_free.svg" />,
    },
    {
      type: CertificateTypes.GLUTEN_FREE,
      text: t('Gluten Free'),
      icon: <ReactSVG src="/certificates/gluten_free.svg" />,
    },
    {
      type: CertificateTypes.ORGANIC_FARMING_IN_EUROPE,
      text: t('Organic farming in Europe'),
      icon: <ReactSVG src="/certificates/gluten_free.svg" />,
    },
    {
      type: CertificateTypes.IFS,
      text: t('IFS'),
      icon: <ReactSVG src="/certificates/IFS.svg" />,
    },
    {
      type: CertificateTypes.ECOCERT_STANDARD_ECODETERGENTS,
      text: t('Ecocert Standard Ecodetergents'),
      icon: <ReactSVG src="/certificates/ecodetergent.svg" />,
    },
    {
      type: CertificateTypes.BCORP,
      text: t('Bcorp'),
      icon: <ReactSVG src="/certificates/Bcorp.svg" />,
    },
  ];

  useEffect(() => {
    productService
      .listDefaultCertificates()
      .response(response => {
        const aux = response.map((certificate: any) => ({...certificate, ...defaultCertificates.find((item: any) => item.type === certificate.certificateType)}));
        setCertificates(aux);
      })
      .error(console.error);
  }, []);

  /**
   * Upload Certificates functions:
   * @param certificate
   */
  const handleAddCertificate = (certificate: {name: string; file?: any}, index: number, isNew = true) => {
    if (isNew) {
      let emptyExists = false;
      for (const c of uploadCertificates) {
        if (!c.file) {
          emptyExists = true;
          (inputFileRef.current! as any).click();
          break;
        }
      }
      if (!emptyExists) {
        setUploadCertificates([...uploadCertificates, certificate]);
        setTimeout(() => (inputFileRef.current! as any).click(), 500);
      }
    } else {
      uploadCertificates[index] = certificate;
      setUploadCertificates([...uploadCertificates]);

      props.handleUploadCertificates(uploadCertificates);
    }
  };

  const handleModifyCertificate = (modifiedName: string, index: number) => {
    uploadCertificates[index].name = modifiedName;
    debounce(() => {
      setUploadCertificates([...uploadCertificates]);
      props.handleUploadCertificates(uploadCertificates);
    });
  };

  const handleRemoveCertificateInput = (index: number) => {
    setUploadCertificates(prevCertificates => {
      const updatedCertificates = [...prevCertificates];
      updatedCertificates.splice(index, 1);
      return updatedCertificates;
    });
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    if (e.target.files) {
      const file = e.target.files[0];

      if (file.type !== 'application/pdf') {
        sharedToast({
          title: t('Certificate file error'),
          description: t('You can only import PDF certificate files'),
          status: TOAST_STATUS.ERROR,
          variant: TOAST_VARIANT.SUBTLE,
        });

        return;
      }

      handleAddCertificate({name: file.name, file}, index, false);
    }
  };

  const handleSelectedCertificate = (certificate: string) => {
    let c = [...selectedCertificates];
    if (selectedCertificates.includes(certificate)) {
      c = selectedCertificates.filter(item => item !== certificate);
    } else {
      c.push(certificate);
    }
    setSelectedCertificates(c);
    props.handleDefaultCertificates(c);
  };

  let timeout: any;
  const debounce = (funcion: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(funcion, 500);
  };

  return (
    <VStack>
      <HStack space={3} flexWrap="wrap" key="certificates">
        {!props.isDisabled && (
          <Button
            variant="outline"
            shadow="2"
            borderRadius={5}
            _text={{color: 'black'}}
            leftIcon={<Icon as={Ionicons} size={5} name="attach" color="black" />}
            mb={5}
            onPress={() => handleAddCertificate({name: '', file: null}, -1)}>
            {t('Attach')}
          </Button>
        )}
        {certificates
          ? certificates.map((item: any) =>
              item.text ? (
                <Button
                  isDisabled={props.isDisabled}
                  key={item.text}
                  variant="outline"
                  shadow="2"
                  borderRadius={5}
                  _text={{color: 'black'}}
                  leftIcon={item.icon}
                  mb={5}
                  bgColor={selectedCertificates.includes(item._id) ? 'secondary.300' : 'inherit'}
                  onPress={() => {
                    handleSelectedCertificate(item._id);
                  }}>
                  {item.text}
                </Button>
              ) : (
                <Button
                  isDisabled={props.isDisabled}
                  key={item.name}
                  variant="outline"
                  shadow="2"
                  borderRadius={5}
                  _text={{color: 'black'}}
                  mb={5}
                  bgColor={selectedCertificates.includes(item._id) ? 'secondary.300' : 'inherit'}
                  onPress={() => {
                    handleSelectedCertificate(item._id);
                  }}>
                  {item.name}
                </Button>
              )
            )
          : null}
      </HStack>

      <FlatList
        data={uploadCertificates}
        renderItem={({item, index}) => (
          <HStack
            alignItems="center"
            space={4}
            mt={4}
            display={uploadCertificates[index].file || uploadCertificates[index].url ? 'inherit' : 'none'}
            w={['500', '100%', '500']}>
            <Input
              isDisabled={props.isDisabled}
              defaultValue={uploadCertificates[index].name}
              onChangeText={text => handleModifyCertificate(text, index)}
              type="text"
              flex={1}
            />
            <input
              type="file"
              ref={inputFileRef}
              hidden
              onChange={event => {
                handleFileChange(event, index);
              }}
            />
            <HStack space={1}>
              {!props.isDisabled && (
                <Icon
                  as={AntDesign}
                  name="delete"
                  size={17}
                  color="primary.500"
                  onPress={() => {
                    handleRemoveCertificateInput(index);
                  }}
                />
              )}
              {item.url && <Icon as={AntDesign} name="eye" size={17} color="primary.500"
onPress={() => window.open(item.url, '_blank', 'noreferrer')} />}
            </HStack>
          </HStack>
        )}
        keyExtractor={(item, index) => index.toString()}
      />
    </VStack>
  );
}

export default React.memo(Certificates);
