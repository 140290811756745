import {AddIcon, Button} from 'native-base';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {ListCustomers} from '../components/ListCustomers';
import {PageLayout} from '../components/PageLayout';

const ListCustomersPage = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  return (
    <PageLayout title={t('List Customers')}>
      <Button onPress={() => navigate('/formUsers')} my={5} px={5} style={{width: 'auto', alignSelf: 'flex-end'}} leftIcon={<AddIcon />}>
        {t('Add User')}
      </Button>
      <ListCustomers />
    </PageLayout>
  );
};

export default ListCustomersPage;
