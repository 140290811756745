import {TagsServiceTypes} from '@bri/rotimpres-core';
import {useBaseService} from '@bri/shared-components';
import {ServiceFunctionsType} from '@bri/shared-core';

export function useTagsService() {
  const baseService = useBaseService();

  const tagsServiceFunctions: ServiceFunctionsType<TagsServiceTypes> = {
    list: params =>
      baseService()
        .auth()
        .get('v1/tags/list', params),
    remove: params =>
      baseService()
        .auth()
        .post('v1/tags/remove', params),
  };

  return tagsServiceFunctions;
}
