import {AxiosError} from 'axios';
import {AddIcon, Box, Button, Center, FlatList, FormControl, Heading, HStack, Icon, Input, Menu, Stack, Text, View, VStack} from 'native-base';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams, useRouteError} from 'react-router-dom';
import {DatePicker, Map, TOAST_STATUS, TOAST_VARIANT, useSharedToast, MultilanguageField, MultilanguageTab} from '@bri/shared-components';
import {ChangeEvent, ComponentProps, useEffect, useRef, useState} from 'react';
import {AntDesign, MaterialCommunityIcons, Ionicons} from '@native-base/icons';
import * as Yup from 'yup';
import {Message} from 'yup';
import ImageUploading, {ImageListType} from 'react-images-uploading';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE, ProductDTO} from '@bri/rotimpres-core';
import {StyleSheet} from 'react-native';
import {useProductsService} from '../services/ProductsService';
import {PageLayout} from '../components/PageLayout';
import Certificates from '../components/Certificates';
import defaultImg from '../images/default.png';
import Config from '../config/config';
import TemplateDevolutionMap from '../files/templateDevolutionMap.csv';
import {Markers} from '../utils/imagesUtils';

import {getCurrentLanguage} from '../utils/languageUtils';

type Props = {
  uploadedDevMap?: {name: string; file?: any};
  uploadedTagsFile?: {name: string; file?: any};
};

const FormProductPage = (props: Props) => {
  const {t} = useTranslation();
  const {productId} = useParams();
  const productService = useProductsService();
  const [product, setProduct] = useState<ProductDTO | null>(null);
  const sharedToast = useSharedToast();
  const [images, setImages] = useState<ImageListType>([]);
  const [promoImages, setPromoImages] = useState<ImageListType[]>([]);
  const [mapZoom, setMapZoom] = useState<number | undefined>(4);
  const [startCords, setStartCords] = useState<{coords: {lat: number; lng: number}}>({coords: {lat: 41.8, lng: 1}});
  const maxNumber = 1;
  const navigate = useNavigate();
  const [submittingCreate, setSubmittingCreate] = useState(false);
  const [submittingPreview, setSubmittingPreview] = useState(false);
  const inputFileRefDevolution = useRef(null);
  const inputFileRefTags = useRef(null);
  const [devolutionMap, setDevolutionMap] = useState<{name: string; file?: any}>(props.uploadedDevMap ?? {name: ''});
  const [returnPoints, setreturnPoints] = useState<{latitude: number; longitude: number; address: string; name: string}[]>([]);
  const actualDate = new Date();
  const twoMonthFromNow = new Date(actualDate.getFullYear(), actualDate.getMonth() + 2, actualDate.getDate());
  const [tagsList, setreturnTagsList] = useState<{tags: string}[]>([]);
  const [tagErrors, setTagErrors] = useState<Record<string, string>>({});
  const [previewLanguage, setPreviewLanguage] = useState(getCurrentLanguage());

  // Comprobación de la existencia de tags de producto
  const checkIfProductTagsAlreadyExist = function(tags: string[]): Promise<Record<string, boolean>> {
    return new Promise((resolve, reject) => {
      productService
        .existingTags({tags})
        .response(data => resolve(data.exists))
        .error(error => reject(error));
    });
  };

  const ProductTagSchema = Yup.string()
    .required(t('Required') as Message)
    .min(8, t('Must be more than 8 digits') as Message)
    .max(50, t('Must be less than 50 digits') as Message)
    .matches(/^[a-zA-Z0-9]+$/, {message: 'Invalid Format'});

  const MultilanguageSchema = (params: {required: boolean}) =>
    Yup.object().test('multilanguageField', t('Required') as Message, (value: {[key: string]: string}) => {
      const text = value && Object.hasOwn(value, DEFAULT_LANGUAGE) ? value[DEFAULT_LANGUAGE] : '';
      return params.required
        ? Yup.string()
            .required()
            .isValidSync(text)
        : Yup.string()
            .notRequired()
            .isValidSync(text);
    });

  const alphanumericRegex = /^[a-zA-Z0-9]+$/;
  const PromotionCodeSchema = () =>
    Yup.string()
      .test('is-alphanumeric', t('Promotion code must be alphanumeric') as Message, value => {
        if (!value || value.match(alphanumericRegex)) {
          return true;
        }
        return false;
      })
      .notRequired();

  const ProductSchema = Yup.object().shape({
    name: MultilanguageSchema({required: true}),
    eligibleQuantity: Yup.number()
      .typeError(t('Must be a number') as Message)
      .required(t('Required') as Message),
    batch: Yup.string().required(t('Required') as Message),
    promotionCode: PromotionCodeSchema(),
    description: MultilanguageSchema({required: true}),
    nft_expiration: Yup.string().required(t('Required') as Message),
    devolution_map: Yup.string().notRequired(),
    promotions: Yup.array().of(
      Yup.object().shape({
        title: MultilanguageSchema({required: true}),
        expiration_date: Yup.string().required(t('Required') as Message),
        description: MultilanguageSchema({required: false}),
      })
    ),
  });

  const onImageChange = (imageList: ImageListType, addUpdateIndex: any) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  const onPromoImageChange = (imageList: ImageListType, addUpdateIndex: any) => {
    setPromoImages(prevImages => {
      const newImages = [...prevImages];
      newImages[addUpdateIndex] = imageList;
      return newImages;
    });
  };

  const handleRemoveImage = (indexToRemove: number) => {
    setPromoImages(prevImages => prevImages.filter((_, index) => index !== indexToRemove));
  };

  const downloadTemplateCSV = () => {
    const a = document.createElement('a');
    a.href = TemplateDevolutionMap;
    a.download = 'templateDevolutionMap.csv';
    a.click();
  };

  useEffect(() => {
    if (productId) {
      productService
        .getDTO({id: productId!})
        .response(response => {
          reset(getInitialValues(response.product));
          setProduct(response.product);
        })
        .error(error => {
          console.error(error);
          sharedToast({
            title: t('Error retrieving product'),
            description: error.data,
            status: TOAST_STATUS.ERROR,
            variant: TOAST_VARIANT.SUBTLE,
          });
        });
    }
  }, []);

  const generateObjProduct = (values: any) => {
    const promotionCode = ((values.promotionCode as string) || '').toUpperCase();

    const objProduct = {
      _id: productId,
      name: JSON.stringify(values.name),
      description: JSON.stringify(values.description),
      tags: tagsList instanceof Array ? tagsList.map((item: any) => item.tags) : [],
      defaultCertificates: values.certificates ? values.certificates.split(',') : [],
      uploadedCertificates: values.uploadedCertificates ?? [],
      uploadedCertificateNames: values.uploadedCertificateNames ?? [],
      expirationDate: values.nft_expiration,
      units: tagsList.length,
      batch: values.batch,
      promotionCode,
      promotions:
        values.promotions instanceof Array && values.promotions.length > 0
          ? JSON.stringify(
              values.promotions.map((item: any) => ({
                name: item.title as string,
                expiration_date: item.expiration_date as Date,
                description: item.description as string,
                promotionCode,
              }))
            )
          : '',
      uploadedPromoImages: promoImages.length > 0 ? promoImages.map((item: any) => item[0].file) : [],
      returnPoints: JSON.stringify(returnPoints) ?? [],
      image: images.length > 0 ? images[0].file : undefined,
    } as ProductDTO;

    return objProduct;
  };

  const submitFormCreate = (values: any) => {
    // Si existen errores de validación con los tags de producto, no continuaremos con la lógica de envío del formulario
    if (Object.keys(tagErrors).length) {
      return;
    }

    const objProduct: ProductDTO = generateObjProduct(values);
    setSubmittingCreate(true);
    productService
      .save(objProduct)
      .response(response => {
        console.log('Resposta', response);
        sharedToast({
          title: t('Product save'),
          description: '',
        });
        navigate(-1);
      })
      .error(error => {
        console.error(error);
        sharedToast({
          title: t('Validation error'),
          description: t('Something went wrong trying to create a new product'),
        });
      })
      .finally(() => setSubmittingCreate(false));
  };

  const submitFormPreview = (values: any) => {
    // Si existen errores de validación con los tags de producto, no continuaremos con la lógica de envío del formulario
    if (Object.keys(tagErrors).length) {
      return;
    }
    const objProduct: ProductDTO = generateObjProduct(values);
    console.log(objProduct);
    setSubmittingPreview(true);
    productService
      .savePreview({...objProduct, language: previewLanguage})
      .response(response => {
        window.open(`${Config.previewProduct.url}/PreviewProduct/${response.product._id}`, '_blank', 'noreferrer');
      })
      .error(error => {
        console.error(error);
        sharedToast({
          title: t('Validation error'),
          description: t('Something went wrong trying to preview a new product'),
        });
      })
      .finally(() => setSubmittingPreview(false));
  };

  const getInitialValues = (productBD: ProductDTO | null): {[key: string]: any} => {
    if (productBD) {
      // RETURN POINTS
      // Valors inicials, obtenir objecte return points a partir d'array d'strings
      let rppPost: {latitude: number; longitude: number; address: string; name: string}[] = []; // -> Format backoffice return points
      if (productBD.returnPoints != '' && productBD.returnPoints != undefined) {
        try {
          let returnPointsParsed: {address: string; name: string; location: {type: string; coordinates: number[]}[]}[] = []; // -> Format server return points
          returnPointsParsed = JSON.parse(productBD.returnPoints);
          returnPointsParsed.forEach(rpp => {
            rppPost.push({
              address: rpp.address,
              name: rpp.name,
              latitude: rpp.location[0].coordinates[0],
              longitude: rpp.location[0].coordinates[1],
            });
          });
        } catch {
          rppPost = [];
        }
      }
      setreturnPoints(rppPost);
      if (rppPost.length > 0) {
        if (rppPost.length == 1) {
          setMapZoom(15);
          setStartCords({coords: {lat: rppPost[0].latitude, lng: rppPost[0].longitude}});
        } else {
          setMapZoom(undefined);
        }
      } else {
        setMapZoom(4);
      }
      return {
        name: productBD.name,
        eligibleQuantity: productBD.units,
        batch: productBD.batch,
        promotionCode: productBD.promotionCode,
        description: productBD.description,
        nft_expiration: productBD.expirationDate ? new Date(productBD.expirationDate) : undefined,
        tags: productBD.tags.map(item => ({tag: item})),
        promotions:
          productBD.promotions != ''
            ? JSON.parse(productBD.promotions).map((item: any, index: number) => ({
                title: item.name,
                description: item.description ?? '',
                expiration_date: new Date(item.expiration_date),
                promotionCode: productBD.promotionCode,
              }))
            : '[]',
        devolution_map: rppPost,
        image: productBD.image,
      };
    }
    return {
      eligibleQuantity: 0,
      tags: [],
      promotions: [{expiration_date: twoMonthFromNow}],
      nft_expiration: twoMonthFromNow,
    };
  };

  const {
    control,
    register,
    setValue,
    reset,
    formState: {errors},
    handleSubmit,
  } = useForm({
    defaultValues: {
      ...getInitialValues(null),
    },

    resolver: yupResolver(ProductSchema),
  });

  const {fields: promotionFields, remove: promotionRemove} = useFieldArray({
    control,
    name: 'promotions', // unique name for your Field Array
  });

  if (productId && !product) return null;
  let formTitle = t('Create Product');
  let formSubmitText = t('Create Product');
  if (productId) {
    formTitle = t('Edit Product');
    formSubmitText = t('Edit');
  }

  const handleUploadCSVDevolution = () => {
    (inputFileRefDevolution.current! as any).click();
  };
  const handleUploadCSVTags = () => {
    (inputFileRefTags.current! as any).click();
  };

  const handleFileChangeDevolution = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files) {
        if (e.target.files[0].type === 'text/csv') {
          setDevolutionMap({name: e.target.files[0].name, file: e.target.files[0]});
          const csvFile = e.target.files[0];
          csvFile.text().then(txt => {
            const csvParsed = txt.split('\n');
            let csvItems: {latitude: number; longitude: number; address: string; name: string}[] = [];
            for (let i = 1; i < csvParsed.length; i++) {
              const csvRow = csvParsed[i].split(';');
              let validFormat = false;
              if (csvRow.length == 4) {
                const latitude = Number.parseFloat(csvRow[2]);
                const longitude = Number.parseFloat(csvRow[3]);

                // Check that the input latitude and longitud are correct
                if (latitude && longitude && !isNaN(latitude) && !isNaN(longitude) && latitude >= -90 && latitude <= 90 && longitude >= -180 && longitude <= 180) {
                  validFormat = true;
                  csvItems.push({latitude, longitude, address: csvRow[1], name: csvRow[0]});
                }
              }
              if (!validFormat) {
                sharedToast({
                  title: t('Incorrect format'),
                  description: t('The uploaded csv does not match the allowed format'),
                  status: TOAST_STATUS.ERROR,
                  variant: TOAST_VARIANT.SUBTLE,
                });
                csvItems = [];
                break;
              }
            }
            setreturnPoints(csvItems);
            if (csvItems.length == 1) {
              setStartCords({coords: {lat: csvItems[0].latitude, lng: csvItems[0].longitude}});
              setMapZoom(undefined);
              setMapZoom(15);
            } else if (csvItems.length == 0) {
              setStartCords({coords: {lat: 41.8, lng: 1}});
              setMapZoom(4);
            } else {
              setMapZoom(undefined);
            }
          });
        }
      }
    } catch {
      setreturnPoints(returnPoints);
    }
  };

  // Comprueba si un tag de producto es válido
  const isValidTag = function(tag: string): string | null {
    try {
      ProductTagSchema.validateSync(tag);
      return null;
    } catch (error) {
      const validationError = error as Yup.ValidationError;
      const newTagErrors: Record<string, string> = {};
      newTagErrors[tag] = validationError.message;
      setTagErrors(newTagErrors);
      return validationError.message;
    }
  };

  const handleFileChangeTags = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files) {
        if (e.target.files[0].type === 'text/csv') {
          const csvFile = e.target.files[0];
          csvFile.text().then(txt => {
            const csvParsedTags = txt.split('\n');
            const csvTags: Set<string> = new Set();
            const validTags: Set<string> = new Set();
            const newTagErrors: Record<string, string> = {};

            for (let i = 1; i < csvParsedTags.length; i++) {
              // trim elimina los espacios en blanco
              const csvRow = csvParsedTags[i].split(';');
              const tag = csvRow[0].trim();
              if (tag.length != 0) {
                csvTags.add(tag);
                const tagError = isValidTag(tag);

                if (tagError) {
                  newTagErrors[tag] = tagError;
                } else {
                  validTags.add(tag);
                }
              }
            }

            // De los tags de producto aparentemente válidos, vamos a comprobar si ya existen/se han registrado con anterioridad
            checkIfProductTagsAlreadyExist(Array.from(validTags)).then(function(exists: Record<string, boolean>) {
              const tagAlreadyExistsErrorMessage = t('Value already exists');

              for (const tag in exists) {
                if (exists[tag]) {
                  newTagErrors[tag] = tagAlreadyExistsErrorMessage;
                }
              }

              setTagErrors(newTagErrors);
            });

            if (!validTags.size) {
              setTagErrors(newTagErrors);
            }

            setreturnTagsList(Array.from(csvTags).map(tag => ({tags: tag})));
          });
        }
      }
    } catch {
      setreturnTagsList(tagsList);
    }
  };
  const stylesTagList = StyleSheet.create({
    container: {
      flex: 1,
      paddingTop: 22,
    },
    item: {
      padding: 10,
      fontSize: 13,
      height: 30,
    },
    itemError: {
      padding: 10,
      fontSize: 13,
      height: 44,
      paddingTop: 0,
    },
  });

  const multilanguageTabError: MultilanguageTab = {
    tabProps: {
      bgColor: 'error.100',
      borderColor: 'error.700',
    },
    tabHoverProps: {
      bgColor: 'error.200',
      borderColor: 'error.700',
    },
  };

  const multilanguageTabErrorSelected: MultilanguageTab = {
    tabProps: {
      bgColor: 'error.300',
      borderColor: 'error.700',
    },
    tabHoverProps: {
      bgColor: 'error.300',
      borderColor: 'error.700',
    },
  };

  const multilanguageValues = (error: any, field: any, required = true) =>
    ({
      languages: AVAILABLE_LANGUAGES.map(lang => ({
        codeName: lang,
        displayName: lang.toUpperCase(),
        tab: required && DEFAULT_LANGUAGE == lang && error ? multilanguageTabError : undefined,
        tabSelected: required && DEFAULT_LANGUAGE == lang && error ? multilanguageTabErrorSelected : undefined,
      })),
      tabBar: {paddingLeft: 2, paddingRight: 2},
      tab: {tabProps: {rounded: 'lg', h: 7}, tabHoverProps: {rounded: 'lg', h: 7}},
      tabSelected: {tabProps: {rounded: 'lg', h: 8}, tabHoverProps: {rounded: 'lg', h: 8}, textProps: {bold: true}},
      setSelectedTab: AVAILABLE_LANGUAGES.findIndex(lang => lang == DEFAULT_LANGUAGE),
      value: field.value,
      onChange: (value: any) => {
        field.onChange(value);
      },
    } as ComponentProps<typeof MultilanguageField>);

  return (
    <PageLayout title={formTitle}>
      <View px={6} py={3} overflowX="auto">
        <HStack alignItems="baseline">
          <Icon as={AntDesign} name="filetext1" size={4} mr="1" color="muted.400" />
          <Heading size="xs">{t('Data')}</Heading>
        </HStack>
        <Stack direction={['column', 'column', 'row']} space={[0, 10, 100]}>
          <Stack direction="column" space={1} flex="4">
            <Stack direction="row" space={5} flex={2} mb={3} alignItems="end">
              <FormControl isRequired isInvalid={'name' in errors} key="name" flex={1}>
                <FormControl.Label _text={{bold: true}}>{t('Name')}</FormControl.Label>
                <Controller
                  name="name"
                  control={control}
                  render={({field}) => (
                    <MultilanguageField {...multilanguageValues(errors.name, field)} inputProps={{type: 'text', isDisabled: productId !== undefined, ref: field.ref}} />
                  )}
                />
                <View h={6}>{errors.name && <FormControl.ErrorMessage>{errors.name?.message}</FormControl.ErrorMessage>}</View>
              </FormControl>

              <FormControl isRequired isInvalid={'eligibleQuantity' in errors} key="eligibleQuantity" flex={1}>
                <FormControl.Label _text={{bold: true}}>{t('Units available')}</FormControl.Label>
                <Controller name="eligibleQuantity" control={control} render={({field}) => <Input type="text" isDisabled {...field} />} />
                <View h={6}>{errors.eligibleQuantity && <FormControl.ErrorMessage>{errors.eligibleQuantity?.message}</FormControl.ErrorMessage>}</View>
              </FormControl>

              <FormControl isRequired isInvalid={'batch' in errors} key="batch" flex={1}>
                <FormControl.Label _text={{bold: true}}>{t('Batch')}</FormControl.Label>
                <Controller name="batch" control={control} render={({field}) => <Input type="text" isDisabled={productId !== undefined} {...field} />} />
                <View h={6}>{errors.batch && <FormControl.ErrorMessage>{errors.batch?.message}</FormControl.ErrorMessage>}</View>
              </FormControl>
            </Stack>

            <Stack>
              <FormControl isRequired isInvalid={'description' in errors} key="description" mb={6}>
                <FormControl.Label _text={{bold: true}}>{t('Description')}</FormControl.Label>
                <Controller
                  name="description"
                  control={control}
                  render={({field}) => (
                    <MultilanguageField
                      {...multilanguageValues(errors.description, field)}
                      inputProps={{type: 'text', isDisabled: productId !== undefined, multiline: true, numberOfLines: 3, ref: field.ref}}
                    />
                  )}
                />
                {errors.description && <FormControl.ErrorMessage>{errors.description?.message}</FormControl.ErrorMessage>}
              </FormControl>
            </Stack>
          </Stack>
          <View flex={3}>
            <ImageUploading value={images} onChange={onImageChange} maxNumber={maxNumber} dataURLKey="data_url">
              {({imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps}) => (
                // write your building UI
                <View bgColor="gray.100" p={4} borderRadius={4}>
                  {imageList.length === 0 && !productId ? (
                    <div {...dragProps}>
                      <View bgColor="secondary.100" p={5} py={8} borderStyle="dashed" borderWidth={1} borderColor="primary.400">
                        <Center>
                          <VStack space={2} alignItems="center">
                            <Icon as={MaterialCommunityIcons} name="folder-multiple" size="10" color="primary.500" />
                            <Text bold fontSize="xs">
                              {t('Drop your product image here')}
                            </Text>
                          </VStack>
                          <HStack width="100%" alignItems="center" space={4} my={4}>
                            <View height={0} borderBottomWidth={1} borderColor="primary.500" flex={1} />
                            <Text color="primary.500">{t('or')}</Text>
                            <View height={0} borderBottomWidth={1} borderColor="primary.500" flex={1} />
                          </HStack>
                          <Button onPress={onImageUpload} {...dragProps}>
                            {t('Search file')}
                          </Button>
                          {/* {errors && ( */}
                          {/*  <View> */}
                          {/*    {errors.maxNumber && <Text>Number of selected images exceed maxNumber</Text>} */}
                          {/*    {errors.acceptType && <Text>Your selected file type is not allow</Text>} */}
                          {/*    {errors.maxFileSize && <Text>Selected file size exceed maxFileSize</Text>} */}
                          {/*    {errors.resolution && <Text>Selected file is not match your desired resolution</Text>} */}
                          {/*  </View> */}
                          {/* )} */}
                        </Center>
                      </View>
                    </div>
                  ) : (
                    imageList.map((image, index) => (
                      <VStack key={index} space={4} alignItems="center">
                        <img src={image.data_url} alt="" width="200" />
                        <View>
                          <Button onPress={() => onImageRemove(index)} width="200">
                            {t('Remove')}
                          </Button>
                        </View>
                      </VStack>
                    ))
                  )}
                  {productId && (
                    <VStack space={4} alignItems="center">
                      <img src={product?.image} alt="" width="200" />
                    </VStack>
                  )}
                </View>
              )}
            </ImageUploading>
          </View>
        </Stack>
        <HStack alignItems="baseline" mt={8} mb={2} flex={1}>
          <Icon as={AntDesign} name="filetext1" size={4} mr="1" color="muted.400" />
          <Heading size="xs">{t('Certificates')}</Heading>
        </HStack>
        <VStack>
          <Input {...register('certificates')} display="none" />
          <Input {...register('uploadedCertificateNames')} display="none" />
          <Input {...register('uploadedCertificates')} display="none" />

          <Certificates
            isDisabled={productId !== undefined}
            selectedCertificates={product ? product.defaultCertificates : []}
            uploadedCertificates={
              product
                ? product.uploadedCertificates.map((cert, index) => ({
                    name: product.uploadedCertificateNames[index],
                    url: cert,
                  }))
                : []
            }
            handleDefaultCertificates={certificates => {
              setValue('certificates', certificates.toString());
            }}
            handleUploadCertificates={certificates => {
              setValue(
                'uploadedCertificateNames',
                certificates.map(item => item.name)
              );
              setValue(
                'uploadedCertificates',
                certificates.map(item => item.file)
              );
            }}
          />
        </VStack>
        <View height={0} borderWidth={1} borderColor="gray.300" my={10} />
        <Stack space={[0, 10, 100]}>
          <Stack direction="row" space={0} flex={2} mb={3}>
            <VStack flex={1}>
              {/* NFT: */}
              <Stack direction={['column', 'row']}>
                <Stack direction="column" zIndex={1}>
                  <FormControl isRequired={false} isInvalid={'nft_expiration' in errors} key="nft_expiration" flex={1}>
                    <FormControl.Label _text={{bold: true}}>{t('NFT expiration date')}</FormControl.Label>
                    <Controller
                      name="nft_expiration"
                      control={control}
                      render={({field}) => (
                        <DatePicker
                          dateFormat="dd/MM/yyy"
                          locale="en"
                          onChange={field.onChange}
                          date={field.value}
                          customInput={
                            <Input
                              type="text"
                              isDisabled={productId !== undefined}
                              InputRightElement={<Icon as={AntDesign} name="calendar" size={4} color="primary.500" mr={2} />}
                              maxW="250"
                            />
                          }
                        />
                      )}
                    />
                    {errors.nft_expiration && <FormControl.ErrorMessage>{errors.nft_expiration?.message}</FormControl.ErrorMessage>}
                  </FormControl>
                </Stack>
                <Stack direction={['column', 'row', 'row', 'row']} alignItems="left">
                  <FormControl isInvalid={'devolution_map' in errors} key="devolution_map">
                    <HStack space={4}>
                      <VStack style={{flex: 1}}>
                        <FormControl.Label _text={{bold: true}} ml="4">
                          {t('Devolution map')}
                        </FormControl.Label>
                        <Controller name="devolution_map" control={control} render={({field}) => <Input ml="4" type="text" isReadOnly value={devolutionMap?.name} />} />
                        {errors.devolution_map && <FormControl.ErrorMessage>{errors.devolution_map?.message}</FormControl.ErrorMessage>}
                      </VStack>
                      <VStack alignItems="center">
                        <FormControl.Label _text={{bold: true}} ml="4">
                          {t(' ')}
                        </FormControl.Label>
                        <Button
                          variant="outline"
                          shadow="2"
                          borderRadius={10}
                          isDisabled={productId !== undefined}
                          _text={{color: 'black'}}
                          leftIcon={<Icon as={Ionicons} size={5} name="attach" color="black" />}
                          onPress={() => handleUploadCSVDevolution()}>
                          {t('Upload')}
                        </Button>
                        <input
                          type="file"
                          ref={inputFileRefDevolution}
                          hidden
                          onChange={event => {
                            handleFileChangeDevolution(event);
                          }}
                        />
                      </VStack>
                    </HStack>
                    <Box ml="4" marginTop={7} rounded="lg" overflow="hidden" backgroundColor="black" shadow="2" borderWidth="0" flex={1} width={363} minHeight={210}>
                      <Map
                        apikey={Config.googleMaps.apiKey}
                        zoom={mapZoom}
                        center={startCords}
                        iconsHidden
                        locations={returnPoints?.map(rtp => ({
                          coords: {
                            lat: rtp.latitude,
                            lng: rtp.longitude,
                          },
                          icon: {
                            symbol: {
                              path: Markers.Bin,
                              fillColor: '#0059A7',
                              fillOpacity: 10,
                              scale: 1,
                              strokeColor: 'transparent',
                            },
                            anchor: {
                              x: 1,
                              y: 1,
                            },
                          },
                        }))}
                      />
                    </Box>
                  </FormControl>
                </Stack>
                {!productId && (
                  <Stack ml={8} mt={6} direction="column">
                    <FormControl.Label _text={{bold: false}} ml="2">
                      <Text>
                        {t('You can get your address coordinates using ')}
                        <a href="https://www.google.com/maps" target="_blank" rel="noreferrer">
                          Google Maps
                        </a>
                        {t('\nFind your address and right click on it, to copy them.')}
                      </Text>
                    </FormControl.Label>
                    <Button width={200} ml="2" marginTop={7} onPress={downloadTemplateCSV} isDisabled={productId !== undefined}>
                      {t('Download template')}
                    </Button>
                  </Stack>
                )}
              </Stack>
              <Stack marginTop={9}>
                {/* PROMOTIONS: */}
                <HStack alignItems="baseline">
                  <Icon as={AntDesign} name="filetext1" size={4} mr="1" color="muted.400" />
                  <Heading size="xs">{t('Promotions')}</Heading>
                </HStack>
                {promotionFields.map((promotionField, index) => (
                  <Stack direction={['column', 'column', 'row']} space={[0, 10, 100]} width={['100%', '100%', '60%']}>
                    <Stack direction="column" space={1} flex={3}>
                      <Stack direction={['column', 'column', 'column', 'row']} space={5} mb={3} zIndex={1} alignItems="end">
                        <FormControl isRequired isInvalid={errors.promotions instanceof Array && errors.promotions[index]} key={`title.${promotionField.id}`} flex={1}>
                          <FormControl.Label _text={{bold: true}} paddingTop={3}>
                            {t('Title')}
                          </FormControl.Label>
                          <Controller
                            name={`promotions.${index}.title`}
                            control={control}
                            render={({field: field1}) => (
                              <MultilanguageField
                                {...multilanguageValues(
                                  errors.promotions instanceof Array && errors.promotions[index]?.title ? errors.promotions[index]?.title : undefined,
                                  field1
                                )}
                                inputProps={{type: 'text', isDisabled: productId !== undefined, ref: field1.ref}}
                              />
                            )}
                          />
                          <View h={6}>
                            {errors.promotions instanceof Array && errors.promotions[index]?.title && (
                              <FormControl.ErrorMessage>{errors.promotions[index]?.title?.message}</FormControl.ErrorMessage>
                            )}
                          </View>
                        </FormControl>

                        <FormControl
                          isRequired
                          isInvalid={errors.promotions instanceof Array && errors.promotions[index]}
                          key={`expiration_date.${promotionField.id}`}
                          flex={1}>
                          <FormControl.Label _text={{bold: true}} paddingTop={3}>
                            {t('Expiration Date')}
                          </FormControl.Label>
                          <Controller
                            name={`promotions.${index}.expiration_date`}
                            control={control}
                            render={({field: field2}) => (
                              <DatePicker
                                dateFormat="dd/MM/yyy"
                                locale="en"
                                onChange={field2.onChange}
                                date={field2.value}
                                customInput={
                                  <Input
                                    type="text"
                                    isDisabled={productId !== undefined}
                                    InputRightElement={<Icon as={AntDesign} name="calendar" size={4} color="primary.500" mr={2} />}
                                  />
                                }
                              />
                            )}
                          />
                          <View h={6}>
                            {errors.promotions instanceof Array && errors.promotions[index]?.expiration_date && (
                              <FormControl.ErrorMessage>{errors.promotions[index]?.expiration_date?.message}</FormControl.ErrorMessage>
                            )}
                          </View>
                        </FormControl>
                      </Stack>

                      <FormControl isRequired={false} key={`description.${promotionField.id}`}>
                        <FormControl.Label _text={{bold: true}}>{t('Description')}</FormControl.Label>
                        <Controller
                          name={`promotions.${index}.description`}
                          control={control}
                          render={({field: field3}) => (
                            <MultilanguageField
                              {...multilanguageValues(
                                errors.promotions instanceof Array && errors.promotions[index]?.description ? errors.promotions[index]?.description : undefined,
                                field3,
                                false
                              )}
                              inputProps={{type: 'text', isDisabled: productId !== undefined, multiline: true, numberOfLines: 3, ref: field3.ref}}
                            />
                          )}
                        />
                      </FormControl>

                      <Stack direction="row" paddingTop={10}>
                        <FormControl isInvalid={'promotionCode' in errors} key="promotionCode" flex={1 / 2}>
                          <FormControl.Label _text={{bold: true}}>{t('Promotion Code')}</FormControl.Label>
                          <Controller name="promotionCode" control={control} render={({field}) => <Input type="text" isDisabled={productId !== undefined} {...field} />} />
                          <View h={6}>{errors.promotionCode && <FormControl.ErrorMessage>{errors.promotionCode?.message}</FormControl.ErrorMessage>}</View>
                        </FormControl>
                      </Stack>
                    </Stack>

                    <View flex={2} flexDirection="row" justifyContent="top" alignItems="start">
                      <ImageUploading value={promoImages[index]} onChange={imageList2 => onPromoImageChange(imageList2, index)} maxNumber={maxNumber} dataURLKey="data_url">
                        {({imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps}) => (
                          // write your building UI
                          <View bgColor="gray.100" p={4} borderRadius={4}>
                            {imageList.length === 0 && !productId ? (
                              <div {...dragProps}>
                                <View bgColor="secondary.100" p={5} py={8} borderStyle="dashed" borderWidth={1} borderColor="primary.400">
                                  <Center>
                                    <VStack space={2} alignItems="center">
                                      <Icon as={MaterialCommunityIcons} name="folder-multiple" size="10" color="primary.500" />
                                      <Text bold fontSize="xs">
                                        {t('Drop your product image here')}
                                      </Text>
                                    </VStack>
                                    <HStack width="100%" alignItems="center" space={4} my={4}>
                                      <View height={0} borderBottomWidth={1} borderColor="primary.500" flex={1} />
                                      <Text color="primary.500">o</Text>
                                      <View height={0} borderBottomWidth={1} borderColor="primary.500" flex={1} />
                                    </HStack>
                                    <Button onPress={onImageUpload} {...dragProps}>
                                      {t('Search file')}
                                    </Button>
                                    {/* {errors && ( */}
                                    {/*  <View> */}
                                    {/*    {errors.maxNumber && <Text>Number of selected images exceed maxNumber</Text>} */}
                                    {/*    {errors.acceptType && <Text>Your selected file type is not allow</Text>} */}
                                    {/*    {errors.maxFileSize && <Text>Selected file size exceed maxFileSize</Text>} */}
                                    {/*    {errors.resolution && <Text>Selected file is not match your desired resolution</Text>} */}
                                    {/*  </View> */}
                                    {/* )} */}
                                  </Center>
                                </View>
                              </div>
                            ) : (
                              imageList.map((image, index2) => (
                                <VStack key={index2} space={4} alignItems="center">
                                  <img src={image.data_url} alt="" height={100} />
                                  <View>
                                    <Button onPress={() => onImageRemove(index2)} width="200">
                                      {t('Remove')}
                                    </Button>
                                  </View>
                                </VStack>
                              ))
                            )}
                            {productId && (
                              <VStack space={4} alignItems="center">
                                <img src={product?.uploadedPromoImages[index] ? product?.uploadedPromoImages[index] : defaultImg} alt="" height={150} />
                              </VStack>
                            )}
                          </View>
                        )}
                      </ImageUploading>

                      <View pt={32}>
                        {!productId && (
                          <Icon
                            as={AntDesign}
                            name="delete"
                            size={17}
                            color="primary.500"
                            onPress={() => {
                              promotionRemove(index);
                              handleRemoveImage(index);
                            }}
                          />
                        )}
                      </View>
                    </View>
                  </Stack>
                ))}
              </Stack>
            </VStack>
          </Stack>
          <Stack direction="column" space={0} flex={1}>
            <HStack alignItems="baseline">
              <Icon as={AntDesign} name="filetext1" size={4} mr="1" color="muted.400" />
              <Heading size="xs">{t('Tag List')}</Heading>
            </HStack>
            <FormControl isRequired isInvalid={errors.tags instanceof Array}>
              <FormControl.Label>
                <Button
                  marginTop="4"
                  variant="outline"
                  shadow="2"
                  borderRadius={10}
                  isDisabled={productId !== undefined}
                  _text={{color: 'black'}}
                  leftIcon={<Icon as={Ionicons} size={5} name="attach" color="black" />}
                  onPress={() => handleUploadCSVTags()}>
                  {t('Load Tag List')}
                </Button>
                <input
                  type="file"
                  ref={inputFileRefTags}
                  hidden
                  onChange={event => {
                    handleFileChangeTags(event);
                  }}
                />
              </FormControl.Label>
            </FormControl>
            <Stack marginTop={6}>
              {!productId ? (
                <FlatList
                  data={tagsList}
                  renderItem={function({item}) {
                    return (
                      <>
                        <Text style={stylesTagList.item}>{item.tags}</Text>
                        {tagErrors && tagErrors[item.tags] && (
                          <Text style={stylesTagList.itemError} color="red.500">
                            {tagErrors[item.tags]}
                          </Text>
                        )}
                      </>
                    );
                  }}
                />
              ) : (
                <FlatList data={product?.tags} renderItem={({item}) => <Text style={stylesTagList.item}>{item}</Text>} />
              )}
            </Stack>
          </Stack>
        </Stack>

        {!productId && (
          <HStack alignSelf="flex-end" space={2}>
            <Button
              onPress={handleSubmit(submitFormPreview)}
              isLoading={submittingPreview}
              isLoadingText={t('Loading preview') as string}
              style={{width: 'auto'}}
              variant="outline"
              borderRadius={5}
              leftIcon={<Icon as={Ionicons} name="eye-outline" />}>
              <HStack justifyContent="center" alignItems="center" space={2}>
                <Text>{t('Preview')}</Text>
                <Menu
                  trigger={triggerProps => (
                    <Button accessibilityLabel="More options menu" {...triggerProps} variant="outline" h={6}>
                      <Text>{previewLanguage.toUpperCase()}</Text>
                    </Button>
                  )}>
                  {AVAILABLE_LANGUAGES.map(lang => (
                    <Menu.Item onPress={() => setPreviewLanguage(lang)}>{t(`lang_${lang}`) as string}</Menu.Item>
                  ))}
                </Menu>
              </HStack>
            </Button>
            <Button
              onPress={handleSubmit(submitFormCreate)}
              isLoading={submittingCreate}
              isLoadingText={t('Creating product') as string}
              style={{width: 'auto'}}
              leftIcon={<AddIcon />}>
              {formSubmitText}
            </Button>
          </HStack>
        )}
      </View>
    </PageLayout>
  );
};

const ErrorBoundary = () => {
  const error = useRouteError() as any;
  const axError = error as AxiosError<any>;
  const navigate = useNavigate();
  return (
    <PageLayout>
      <View style={{flex: 1, paddingHorizontal: 20, paddingVertical: 10}}>
        <VStack>
          <Heading>Something went wrong</Heading>
          <Text>{error.statusText}</Text>
          <Text>{axError && ((axError.response?.data!.message as unknown) as string)}</Text>
          <Button onPress={() => navigate(-1)} width="auto">
            Go back
          </Button>
        </VStack>
      </View>
    </PageLayout>
  );
};

export default Object.assign(FormProductPage, {
  ErrorBoundary,
});
