import {CustomDataTable, DataRowRequiredData} from '@bri/shared-components';
import {useEffect, useState} from 'react';
import {Heading, Icon, Pressable, VStack} from 'native-base';
import {TableColumn, TableStyles} from 'react-data-table-component';
import {useTranslation} from 'react-i18next';
import {IUser, USER_ROLE} from '@bri/rotimpres-core';
import {useNavigate} from 'react-router-dom';
import {AntDesign} from '@native-base/icons';
import {SortCriteria} from '@bri/shared-core';
import {useUserService} from '../services/UsersService';

type DataRow = DataRowRequiredData & IUser;

export function ListCustomers() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const userService = useUserService();
  const [users, setUsers] = useState<DataRow[]>([]);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sort, setSort] = useState<SortCriteria>({});

  useEffect(() => {
    const fetchData = async () => {
      userService
        .list({role: USER_ROLE.CLIENT, page, pageSize, sort})
        .apiResponse(() => setLoading(false))
        .response((resp: any) => setUsers(resp.results as any))
        .error((err: any) => console.log(err));
    };
    fetchData().catch(console.error);
  }, []);

  if (loading) return null;

  const customStyles: TableStyles = {
    tableWrapper: {
      style: {
        padding: 4,
      },
    },
    table: {
      style: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
      },
    },
    header: {
      style: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
      },
    },
    headCells: {
      style: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
        fontSize: 14,
      },
    },
    pagination: {
      style: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
      },
    },
  };

  const columns: TableColumn<DataRow>[] = [
    {
      name: t('Customer Name'),
      selector: (row: DataRow) => row.name as string,
      sortable: true,
      grow: 2,
      cell: item => (
        <Heading fontSize="14" justifyItems="self-start">
          {item.name}
        </Heading>
      ),
    },
    {
      name: t('Email'),
      center: true,
      style: customStyles,
      selector: (row: DataRow) => row.email as string,
      sortable: true,
    },
    {
      name: t('Phone'),
      center: true,
      style: customStyles,
      selector: (row: DataRow) => row.phone as string,
    },
    {
      name: t('Webpage'),
      center: true,
      style: customStyles,
      selector: (row: DataRow) => row.url as string,
    },
    {
      name: t('City'),
      center: true,
      style: customStyles,
      selector: (row: DataRow) => row.address?.addressLocality as string,
    },
    {
      name: t('Country'),
      center: true,
      style: customStyles,
      selector: (row: DataRow) => row.address?.addressCountry as string,
    },
    {
      name: t('Language'),
      center: true,
      style: customStyles,
      selector: (row: DataRow) => row.language as string,
    },
    {
      name: t('Job Title'),
      center: true,
      style: customStyles,
      selector: (row: DataRow) => row.jobTitle as string,
    },
    {
      name: t('Products'),
      center: true,
      style: customStyles,
      selector: (row: DataRow) => row.productsCount as string,
    },
    {
      name: t('Users'),
      center: true,
      style: customStyles,
      selector: (row: DataRow) => row.usersCount as string,
    },
    {
      name: t('User List'),
      center: true,
      style: customStyles,
      cell: item => (
        <Pressable
          onPress={() => {
            navigate(`/listUsers/${item._id}`);
          }}>
          <Icon as={AntDesign} name="eyeo" size={4} color="primary.400" />
        </Pressable>
      ),
    },
  ];

  return (
    <VStack space={2} alignItems="center">
      <CustomDataTable
        columns={columns}
        data={users}
        export
        showExtensions={false}
        defaultActions={false}
        customStyles={customStyles}
        defaultSortField="Customer Name"
        pagination
        onChangePage={newPage => setPage(newPage)}
        onChangeRowsPerPage={newPageSize => setPageSize(newPageSize)}
        onSort={(newSort: SortCriteria) => setSort(newSort)}
      />
    </VStack>
  );
}
