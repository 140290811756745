import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import {extendTheme, NativeBaseProvider} from 'native-base';
import fontsCSS from '@native-base/icons/FontsCSS';
import {StorageService} from '@bri/shared-components';
import './index.css';
import '@bri/shared-components/dist/index.css';
import App from './App';

const style = document.createElement('style');
style.type = 'text/css';
style.appendChild(document.createTextNode(fontsCSS));
document.head.appendChild(style);

StorageService.setStorage(localStorage);

const theme = extendTheme({
  fontConfig: {
    Avenir: {
      100: {
        normal: 'Avenir-Light',
      },
      200: {
        normal: 'Avenir-Light',
      },
      300: {
        normal: 'Avenir-Regular',
      },
      400: {
        normal: 'Avenir-Regular',
      },
      500: {
        normal: 'Avenir-Regular',
      },
      600: {
        normal: 'Avenir-Regular',
      },
      700: {
        normal: 'Avenir-Black',
      },
    },
  },
  fonts: {
    heading: 'Avenir',
    body: 'Avenir',
    mono: 'Avenir',
    custom: 'Avenir',
  },
  fontSizes: {
    '2xs': 10,
    xs: 12,
    sm: 14,
    md: 16,
    lg: 22,
    xl: 20,
    '2xl': 24,
    '3xl': 30,
    '4xl': 36,
    '5xl': 48,
    '6xl': 60,
    '7xl': 72,
    '8xl': 96,
    '9xl': 128,
  },
  colors: {
    primary: {
      100: '#e6f5ff',
      200: '#b6daff',
      300: '#7ebfff',
      400: '#189dff',
      500: '#0059A7',
      600: '#004d8f',
      700: '#003c6e',
      800: '#003053',
      900: '#002646',
    },
    secondary: {
      100: '#feffff',
      200: '#eff8fd',
      300: '#dff1fb',
      400: '#d0e9f8',
      500: '#c3e3f6',
      600: '#b9def3',
      700: '#aad5ee',
      800: '#a3d0ea',
      900: '#9fcee8',
    },
  },
  components: {
    Button: {
      // Can simply pass default props to change default behaviour of components.
      baseStyle: (props: any) => {
        // if (props.variant === 'outline') {
        //   return {
        //     rounded: 'full',
        //     borderBottomColor: `${props.colorScheme}.500`,
        //     borderLeftColor: `${props.colorScheme}.500`,
        //     borderRightColor: `${props.colorScheme}.500`,
        //     borderTopColor: `${props.colorScheme}.500`,
        //   };
        // }
        if (props.variant === 'login') {
          return {
            bg: 'primary.500',
            borderRadius: 7,
            width: '100%',
            _hover: {
              bg: `primary.600`,
            },
            _pressed: {
              bg: `primary.700`,
            },
            _text: {
              color: `primary.50`,
            },
            borderBottomColor: 'primary.500',
          };
        }
      },
    },
    Input: {
      baseStyle: {
        backgroundColor: 'secondary.200',
        borderRadius: 7,
        borderWidth: 0,
      },
      defaultProps: {
        size: 'md',
      },
      // variants: {
      //   login: () => ({
      //     borderWidth: 1,
      //   }),
      // },
    },
  },
  config: {
    initialColorMode: 'light',
  },
});

// extend the theme
type MyThemeType = typeof theme;
declare module 'native-base' {
  type ICustomTheme = MyThemeType;
}

ReactDOM.render(
  <React.StrictMode>
    <NativeBaseProvider theme={theme}>
      <App />
    </NativeBaseProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
