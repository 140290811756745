import {
  ConfirmationDialogProvider,
  CookiesBanner,
  INTERNAL_EVENTS,
  SharedComponentsProvider,
  TOAST_STATUS,
  TOAST_VARIANT,
  useEventBus,
  useSharedToast,
} from '@bri/shared-components';
import {RecoilRoot} from 'recoil';
import React, {useEffect} from 'react';
import {RouterProvider} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {router} from './router';
import Config from './config/config';

function App() {
  const eventBus = useEventBus();
  const sharedToast = useSharedToast();
  const {t} = useTranslation();

  useEffect(() => {
    const listenerHandler = (error: any) => {
      sharedToast({
        title: t('Something went wrong'),
        description: error.message,
        status: TOAST_STATUS.ERROR,
        variant: TOAST_VARIANT.SUBTLE,
        isClosable: true,
      });
    };
    eventBus.addListener(INTERNAL_EVENTS.REDIRECT, listenerHandler);

    return () => eventBus.removeListener(listenerHandler);
  }, []);

  return (
    <>
      <RecoilRoot>
        <SharedComponentsProvider config={Config}>
          <ConfirmationDialogProvider>
            <RouterProvider router={router} />
            <CookiesBanner maxWidth={window.innerWidth - 40} />
          </ConfirmationDialogProvider>
        </SharedComponentsProvider>
      </RecoilRoot>
    </>
  );
}

export default App;
