import {ProductServiceTypes as ProductServiceTypesRC} from '@bri/rotimpres-core';
import {encodeJSONParams, useBaseService} from '@bri/shared-components';
import {ServiceFunctionsType} from '@bri/shared-core';

export type ProductServiceTypes = Omit<ProductServiceTypesRC, 'transferTagNewOwner' | 'requestTransfer'>;

export function useProductsService() {
  const baseService = useBaseService();

  const productsServiceFunctions: ServiceFunctionsType<ProductServiceTypes> = {
    save: params =>
      baseService()
        .auth()
        .postFile('v1/products/save', params, ['uploadedCertificates', 'uploadedPromoImages']),
    getDTO: params =>
      baseService()
        .auth()
        .get('v1/products/getDTO', params),
    getDTOEdit: params =>
      baseService()
        .auth()
        .get('v1/products/getDTOEdit', params),
    list: params =>
      baseService()
        .auth()
        .get('v1/products/list', {
          ...params,
          sort: encodeJSONParams(params?.sort),
        }),
    listDefaultCertificates: params =>
      baseService()
        .auth()
        .get('v1/products/listDefaultCertificates', params),
    listUserTags: params =>
      baseService()
        .auth()
        .get('v1/products/listUserTags', params),
    getByTag: params =>
      baseService()
        .auth()
        .get('v1/products/getByTag', params),
    validate: params =>
      baseService()
        .auth()
        .get('v1/products/listUserTags', params),
    transferTagToUser: params =>
      baseService()
        .auth()
        .get('v1/products/transferTagToUser', params),
    getPreview: params => baseService().get('v1/products/getPreview', params),
    savePreview: params =>
      baseService()
        .auth()
        .postFile('v1/products/savePreview', params, ['uploadedCertificates', 'uploadedPromoImages']),
    savePreviewEdit: params =>
      baseService()
        .auth()
        .postFile('v1/products/savePreviewEdit', params, ['promoImages']),
    existingTags: params =>
      baseService()
        .auth()
        .get('v1/products/existingTags', params),
    updateTags: params =>
      baseService()
        .auth()
        .postFile('v1/products/updateTags', params, ['promoImages']),
    listUserPromotions: params =>
      baseService()
        .auth()
        .get('v1/products/listUserPromotions', params),
  };

  return productsServiceFunctions;
}
