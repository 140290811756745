import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {useParams} from 'react-router-dom';
import {DataRow, ListUsers} from '../components/ListUsers';
import {PageLayout} from '../components/PageLayout';
import {ListAllUsers} from '../components/ListAllUsers';
// import 'moment/locale/es';

const ListUsersPage = () => {
  const {t} = useTranslation();
  const {customerId} = useParams();

  const columns = [
    {label: t('First name'), field: 'name', sortField: 'name', filterable: true, filterOptions: {fields: ['name', 'familyName'], label: t('Name')!}},
    {label: t('Last name'), field: 'familyName', sortField: 'familyName'},
    {label: t('Email'), field: 'email', sortField: 'email'},
    {label: t('Phone number'), field: 'telephone', sortField: 'telephone'},
    {label: t('Country'), field: 'address.addressCountry', sortField: 'address.addressCountry'},
    {
      label: t('Birth date'),
      sortField: 'birthDate',
      field: 'birthDate',
      format: (row: DataRow) =>
        moment(row.birthDate) /* .locale('es-es') */
          .format('LL'),
    },
    {label: t('Prefered language'), field: 'language', sortField: 'language'},
    {
      label: t('Gender'),
      field: 'gender',
      sortField: 'gender',
      format: (row: DataRow) => {
        const genders: Record<string, string> = {
          m: t('Male'),
          f: t('Female'),
          nb: t('Non-binary'),
          other: t('Other'),
          nc: t('Unknown'),
        };

        if (!row.gender || !(row.gender in genders)) {
          return '-';
        }

        return genders[row.gender];
      },
    },
    {label: t('Tags'), field: 'nTags', sortField: 'nTags'},
    {label: t('Type'), field: 'type', sortField: 'type'},
  ];

  return <PageLayout title={t('List Users')}>{customerId ? <ListUsers clientId={customerId} columns={columns} /> : <ListAllUsers />}</PageLayout>;
};

export default ListUsersPage;
