import {CustomDataTable, DataRowRequiredData} from '@bri/shared-components';
import {FC, useEffect, useState} from 'react';
import {VStack} from 'native-base';
import {TableColumn, TableStyles} from 'react-data-table-component';
import {useTranslation} from 'react-i18next';
import {IProduct, ITag, IUser, MultilanguageStringGeneric} from '@bri/rotimpres-core';
import moment from 'moment';
import {useTagsService} from '../services/TagsService';

type Props = {userId: IUser['_id']};
type DataRow = DataRowRequiredData & (ITag & {productName: IProduct['name']});

export const ListUserTags: FC<Props> = ({userId}) => {
  const {t, i18n} = useTranslation();
  const tagsService = useTagsService();
  const [tags, setTags] = useState<DataRow[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      tagsService
        .list({userId, page: 1, pageSize: 10})
        .apiResponse(() => setLoading(false))
        .response(data => setTags(data.results as DataRow[]))
        .error((err: any) => console.log(err));
    };

    fetchData().catch(console.error);
  }, []);

  if (loading) return null;

  const customStyles: TableStyles = {
    tableWrapper: {
      style: {
        padding: 4,
      },
    },
    table: {
      style: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
      },
    },
    header: {
      style: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
      },
    },
    headCells: {
      style: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
        fontSize: 14,
      },
    },
    pagination: {
      style: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
      },
    },
  };

  const currentDate = new Date();
  const fallbackLanguage = 'es';

  const columns: TableColumn<DataRow>[] = [
    {
      name: t('Code'),
      style: customStyles,
      selector: (row: DataRow) => row.code,
      sortable: true,
    },
    {
      name: t('Product'),
      style: customStyles,
      selector: (row: DataRow) => {
        const name = (row.productName as unknown) as MultilanguageStringGeneric;
        return name[i18n.language] || name[fallbackLanguage];
      },
      sortable: true,
    },
    {
      name: t('Status'),
      center: true,
      style: customStyles,
      selector: (row: DataRow) => {
        const expirationDate = new Date((row.expirationDate as unknown) as string);

        if (currentDate > expirationDate) return t('Expired')!;
        if (row.active) return t('Active')!;
        return '-';
      },
    },
    {
      name: t('Claimed At'),
      center: true,
      style: customStyles,
      selector: (row: DataRow) => moment((row.claimDate as unknown) as string).format('LL'),
    },
  ];

  return (
    <VStack space={2} alignItems="center">
      <CustomDataTable
        columns={columns}
        data={tags}
        export
        showExtensions={false}
        defaultActions={false}
        customStyles={customStyles}
        // defaultSortField="Customer Name"
        pagination
      />
    </VStack>
  );
};
