import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {PageLayout} from '../components/PageLayout';
import {ListUserTags} from '../components/ListUserTags';

const ListUsersTagsPage = () => {
  const {t} = useTranslation();
  const {userId} = useParams();

  if (!userId) return null;

  return (
    <PageLayout title={t('List User Tags')}>
      <ListUserTags userId={userId} />
    </PageLayout>
  );
};

export default ListUsersTagsPage;
