import React, {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {useColorMode, View} from 'native-base';
import '../App.css';
import {COOKIES_TYPES, CookiesService, loggedUser, useAuthService} from '@bri/shared-components';
import Navbar from '../components/navbar/Navbar';
import {changeLanguage} from '../utils/languageUtils';

const Layout = () => {
  const {colorMode} = useColorMode();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useRecoilState(loggedUser);
  const authService = useAuthService();

  // Si token i no usuari, recuperem usuari
  useEffect(() => {
    const fetchData = async () => {
      const token = await CookiesService.getType(COOKIES_TYPES.TECHNICAL, 'access_token');
      if (token && !user) {
        authService
          .me()
          .response(resp => {
            setUser(resp.user);
            changeLanguage(resp.user.language);
          })
          .error(err => {
            setUser(null);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setUser(null);
        setLoading(false);
      }
    };
    fetchData().catch(error => {
      setLoading(false);
      console.error(error);
    });
  }, []);

  if (loading) return null;

  return (
    <>
      <View bg={colorMode === 'light' ? 'coolGray.100' : 'coolGray.900'} height="100%">
        {user && <Navbar />}
        <Outlet />
      </View>
    </>
  );
};

export default Layout;
