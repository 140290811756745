import {Box, Button, Heading, HStack, Image, Text, useColorMode, VStack} from 'native-base';
import {useTranslation} from 'react-i18next';
import {FC, useState} from 'react';
import {useNavigate, useRouteError} from 'react-router-dom';
import {AxiosError} from 'axios';
import {loggedUser, useSharedToast, useUserService} from '@bri/shared-components';
import {useRecoilState} from 'recoil';
import {AVAILABLE_LANGUAGES, AVAILABLE_LANGUAGES_TYPE} from '@bri/rotimpres-core';
import {changeLanguage} from '../utils/languageUtils';
import logo from '../images/logo.png';

const SamplePage: FC = () => {
  const {t} = useTranslation();
  const {colorMode} = useColorMode();
  const [loading, setLoading] = useState(false);
  const usersService = useUserService();
  const [user, setUser] = useRecoilState(loggedUser);
  const sharedToast = useSharedToast();

  const clickChangeLanguage = (lng: AVAILABLE_LANGUAGES_TYPE) => {
    if (loading) return;
    if (!user) return;

    setLoading(true);
    usersService
      .edit({id: user?._id, language: lng})
      .response(res => {
        setUser(res.user);
        changeLanguage(lng);
      })
      .error(e => {
        console.error(e);
        sharedToast({
          title: t('Error'),
          description: t('Something went wrong changing the language'),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="outlet" style={{zIndex: -1}}>
        <Box bg={colorMode === 'light' ? 'coolGray.50' : 'coolGray.900'} px={4} pt={4} pb={4}
minHeight="100vh" width="100%">
          <VStack space={5} alignItems="center">
            <Image source={{uri: logo}} resizeMode="contain" size={240} alt="NativeBase logo" />
            <Heading size="lg">{t('Welcome to NFCTrack')}</Heading>
            <Text mt={5}>{t('Multi language support')}</Text>
            <HStack space={3}>
              {AVAILABLE_LANGUAGES.map(lng => (
                <Button onPress={() => clickChangeLanguage(lng)}>{t(`lang_${lng}`)}</Button>
              ))}
            </HStack>
          </VStack>
        </Box>
      </div>
    </>
  );
};

const ErrorBoundary = () => {
  const error = useRouteError() as any;
  const axError = error as AxiosError<any>;
  const navigate = useNavigate();
  return (
    <>
      <h1>Something went wrong</h1>
      <p>{error.statusText}</p>
      <p>{axError && ((axError.response?.data!.message as unknown) as string)}</p>
      <Button onPress={() => navigate(-1)}>Go back</Button>
    </>
  );
};

export default Object.assign(SamplePage, {
  ErrorBoundary,
});
