import {UsersServiceTypes as UsersServiceTypesRC} from '@bri/rotimpres-core';
import {encodeJSONParams, useBaseService} from '@bri/shared-components';
import {ServiceFunctionsType} from '@bri/shared-core';

export type UsersServiceTypes = Pick<UsersServiceTypesRC, 'list' | 'listClientUsers' | 'listClientUsersById'>;

export function useUserService() {
  const baseService = useBaseService();

  const usersServiceFunctions: ServiceFunctionsType<UsersServiceTypes> = {
    list: params =>
      baseService()
        .auth()
        .get('/v1/users/list', {
          ...params,
          sort: encodeJSONParams(params?.sort),
          filters: encodeJSONParams(params?.filters),
        }),
    listClientUsers: params =>
      baseService()
        .auth()
        .get('/v1/users/listClientUsers', {
          ...params,
          sort: encodeJSONParams(params?.sort),
          filters: encodeJSONParams(params?.filters),
        }),
    listClientUsersById: params =>
      baseService()
        .auth()
        .get('/v1/users/listClientUsersById', {
          ...params,
          sort: encodeJSONParams(params?.sort),
          filters: encodeJSONParams(params?.filters),
        }),
  };

  return usersServiceFunctions;
}
